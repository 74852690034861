import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReportSelfAcceptanceComponent} from './report-self-acceptance.component';
import {MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatRadioModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';



@NgModule({
  declarations: [ReportSelfAcceptanceComponent],
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        FlexLayoutModule,
        MatDialogModule
    ]
})
export class ReportSelfAcceptanceModule { }

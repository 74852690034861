import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'elastic-countdown-timer',
  template: `
    <div><span *ngIf="days > 0">{{ days }} Days, </span><span *ngIf="hours > 0">{{ hours.toString().length == 1 ? '0' +  hours.toString() : hours }}:</span>{{ minutes.toString().length == 1 ? '0' + minutes.toString() : minutes }}:{{ seconds.toString().length == 1 ? '0' + seconds.toString() : seconds}}</div>
  `,
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() endDate: Date = new Date('2025-03-01'); // Setze hier dein Ziel-Datum
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  private subscription: any;

  ngOnInit() {
    this.updateCountdown();
    this.subscription = interval(1000).subscribe(() => this.updateCountdown());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateCountdown() {
    const now = new Date().getTime();
    const distance = this.endDate.getTime() - now;
    if (distance < 0) {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    } else {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }


  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDriverEntryComponent } from './edit-driver-entry.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {MatDividerModule, MatTooltipModule} from '@angular/material';



@NgModule({
  declarations: [EditDriverEntryComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatDividerModule,
        MatTooltipModule
    ],
  exports: [EditDriverEntryComponent]
})
export class EditDriverEntryModule { }

import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '../../global.service';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {EventsService} from '../events/events.service';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private eventsService: EventsService

  ) { }

  saveGameServerConfig(server_id, config):any {
    if (config.settings) {
      config.settings.data.formationLapType = config.settings.data.formationLapType * 1;
    }
    return this.httpClient.post(this.globalService.apiUrl + '/server/saveServerConfig/' + server_id , {config: config}, {reportProgress: false, observe: 'events'});

  }

  uploadServerConfig(server_id): any {

    return this.httpClient.get(this.globalService.apiUrl + '/server/uploadConfig/' + server_id , {reportProgress: false, observe: 'events'});

  }

  getRekoStats(stats): any {

    return this.httpClient.post(this.globalService.apiUrl + '/admin/reko/getRekoStats', {stats});

  }

  getNews(): any {

    return this.httpClient.get(this.globalService.apiUrl + '/news/get', {observe: 'events'});

  }

  postNews(data, file): any {

    const formData: FormData = new FormData();

    if (file) {
      formData.append('file', file, file.name);
      formData.append('type', 'newsPicture');
    }

    formData.append('id', data.id);
    formData.append('headline_de', data.headline_de);
    formData.append('headline_en', data.headline_en);
    formData.append('text_de', data.text_de);
    formData.append('text_en', data.text_en);

    return this.httpClient.post(this.globalService.apiUrl + '/news/post' , formData, {reportProgress: false, observe: 'events'});

  }

  deleteNews(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/news/delete/' + id , {reportProgress: false, observe: 'events'});
  }

  getGameServers(category = '', community = ''): any {
    return this.httpClient.get(this.globalService.apiUrl + '/server/getServers?category=' + category + '&com=' + community ,  {reportProgress: false, observe: 'events'});
  }

  updateServerStatus(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/server/getServers?id=' + id ,  {reportProgress: false, observe: 'events'});
  }

  getGameServersNoStatus(category = ''): any {
    return this.httpClient.get(this.globalService.apiUrl + '/server/getServers?category=' + category + '&nostatus=true' ,  {reportProgress: false, observe: 'events'});
  }

  getGameServersNoStatus2(category = ''): any {
    return this.httpClient.get(this.globalService.apiUrl + '/server/getServers?category=' + category + '&nostatus=true' );
  }

  getGameServerCategories(): any {
    return this.httpClient.get(this.globalService.apiUrl + '/server/getCategories' ,  {reportProgress: false});
  }


  saveFaqCategory(data): any {
    return this.httpClient.post(this.globalService.apiUrl + '/faq/saveCategory' , data);
  }

  saveOrder(data): any {
    return this.httpClient.post(this.globalService.apiUrl + '/faq/saveCategoryOrder' , {categories: data});
  }

  getFaqCategoryContent(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/faq/getCategoryContent/' + id);
  }

  saveQuestion(question): any {
    return this.httpClient.post(this.globalService.apiUrl + '/faq/saveQuestion' , {question: question});
  }

  saveQuestionOrder(faq): any {
    return this.httpClient.post(this.globalService.apiUrl + '/faq/saveQuestionOrder' , {faq: faq});
  }

  deleteQuestion(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/faq/deleteQuestion/' + id);
  }

  deleteCategory(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/faq/deleteCategory/' + id);
  }

}

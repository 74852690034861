import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {EventsService} from '../../events.service';
import {GlobalService} from '../../../../global.service';
import {ROUTE_TRANSITION} from '../../../../app.animation';
import {AuthenticationService} from '../../../../authentication.service';
import {UploadLiveryComponent} from '../../../teams/team-liveries/upload-livery/upload-livery.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'elastic-edit-driver-entry',
  templateUrl: './edit-driver-entry.component.html',
  styleUrls: ['./edit-driver-entry.component.scss']
})
export class EditDriverEntryComponent implements OnInit {

  driverEntry: any = {};
  responseMessage = '';
  responseError: boolean = false;
  activeCarClasses = [];
  allowedCars = [];
  self: any = false;
  carChangeForbidden: false;

  zipfile: File;

  liveries = [];

  pending_liveries = [];

  liveriesLoaded = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private eventsService: EventsService,
              private globalServie: GlobalService, private globalService: GlobalService,
              private auth: AuthenticationService,
              public dialogRef: MatDialogRef<EditDriverEntryComponent>, private cdr: ChangeDetectorRef,
              private dialog: MatDialog) { }

  getUsersLiveries() {
    if (this.data.eventDetails.sim_id == 1) {
      this.globalServie.getSpecificUsersLiveries(this.driverEntry.user_id).subscribe( (data: any) => {
        if (data.length > 0) {
          this.liveries = data;
        }
      });
    }
  }

  ngOnInit() {



    //console.log(this.data.eventDetails);

    this.data.eventDetails.settings.championship_settings.car_classes.forEach(function(carClass) {
      this.activeCarClasses.push(carClass.class);
      carClass.allowed_cars.forEach((c: any) => {
        this.allowedCars.push(c);
      });
    }, this);


    this.eventsService.getCupCategories();
    this.eventsService.getDriverCategories();
    this.eventsService.getCars();


    if (this.data.self) {
      this.self = true;

      this.eventsService.getDriverEntryFromSteamId(this.data.driver.steamid, this.data.event).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              this.driverEntry = response.body;
              if (this.driverEntry.livery_zip_id != '') {
                this.driverEntry.livery = this.driverEntry.livery_zip_id;
              } else {
                this.driverEntry.livery = 0;
              }
              this.getUsersLiveries();

              if (this.data.eventDetails.full_custom_liveries == 2) {
                this.globalService.getSpecificUsersLiveriesForEvent(this.driverEntry.user_id, this.data.event).subscribe( (data: any) => {
                  this.liveries = data;
                  this.cdr.detectChanges();

                  this.cdr.detectChanges();
                  this.globalService.getUserPendingEventLiveries(this.driverEntry.user_id, this.data.event).subscribe( (data: any) => {
                    this.liveriesLoaded = true;
                    if (data) {
                      this.pending_liveries = data;
                      this.cdr.detectChanges();
                    }
                  });
                });
              }
              break;

          }
        });
    } else {
      this.eventsService.getDriverEntryFromSteamId(this.data.driver.drivers[0].playerID, this.data.event).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              this.driverEntry = response.body;
              if (this.driverEntry.livery_zip_id != '') {
                this.driverEntry.livery = this.driverEntry.livery_zip_id;
              } else {
                this.driverEntry.livery = 0;
              }
              this.getUsersLiveries();
              //console.log(this.driverEntry);
              break;
          }
        });
    }



  }

  save() {

    let saveObject = {
      'participant_id' : this.driverEntry.participant_id,
      'car_model' : this.driverEntry.car_model,
      'car_number' : this.driverEntry.car_number,
      'event_id' : this.driverEntry.event_id,
      'user_id' : this.driverEntry.user_id,
      'cup_category': this.driverEntry.cup_category,
      'custom_car': this.driverEntry.custom_car,
      'livery': this.driverEntry.livery != '' ? this.driverEntry.livery : 0
    };


    this.eventsService.updateDriverEntry(saveObject).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.responseMessage = response.body.message;
            this.responseError = !response.body.success;
            break;
        }
      });

  }

  widthdraw() {
    if (confirm('Are you sure? You can not re-register to this event if you withdraw!')) {
      this.eventsService.setDriverInactive({drivers: [{playerID: this.data.driver.steamid}]}, this.data.event).subscribe(( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.responseMessage = response.body.message;
            this.responseError = !response.body.success;
            this.dialogRef.close();
            break;
        }
      });
    }

  }

  handleZip(files: FileList) {
    if ((files.item(0).size / 1024) > 102400) {
      alert('Your file is bigger than 100MB');
      return;
    } else {
      this.zipfile = files.item(0);
      if (this.driverEntry.user_id !== 0) {
        this.uploadZipFile();
      }
    }
    console.log(this.zipfile);
  }

  uploadZipFile() {
    this.globalServie.uploadSingleEventLivery(this.zipfile, this.driverEntry.user_id, this.data.eventDetails).subscribe(( response: HttpEvent<any> ) => {
      switch (response.type) {
        case HttpEventType.Response:
          if (response.body.success) {
            this.dialogRef.close();
          } else {
            if (response.body.message) {
              alert(response.body.message);
            }
          }
          break;
      }
    });
  }

  openUploadModal() {
    console.log(this.driverEntry);
    console.log(this.data.eventDetails);
    if (this.driverEntry.user_id != 0 && this.data.eventDetails.free_livery_upload == 1) {
      this.dialog.open(UploadLiveryComponent, {
        disableClose: false, data: {teamId: false, userId: this.driverEntry.user_id, eventupload: true, eventId: this.data.eventDetails.event_id, p_id: false, sim: this.data.eventDetails.sim_id, car_model: this.driverEntry.car_model}, minWidth: window.innerWidth - 400 < 200 ? window.innerWidth - 10 : 800, maxWidth: window.innerWidth - 400 < 200 ? window.innerWidth - 10 : 800
      }).afterClosed().subscribe(result => {
        if (this.data.event.full_custom_liveries == 2) {
          this.globalService.getSpecificUsersLiveriesForEvent(this.driverEntry.user_id, this.data.eventDetails.event_id).subscribe( (data: any) => {
            this.liveries = data;
            this.cdr.detectChanges();
          });
          this.globalService.getUserPendingEventLiveries(this.driverEntry.user_id, this.data.eventDetails.event_id).subscribe( (data: any) => {
            this.pending_liveries = data;
            this.cdr.detectChanges();
          });
        }

      });
    }
  }



}

import { Component, OnDestroy, OnInit } from '@angular/core';
import {interval, Observable, Subscription} from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers/index';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';
import { SetCurrentlyOpenByRouteAction } from '../../sidenav/shared/sidenav.action';
import {GlobalService} from '../../../global.service';

@Component({
  selector: 'elastic-toolbar-navigation',
  templateUrl: './toolbar-navigation.component.html',
  styleUrls: ['./toolbar-navigation.component.scss']
})
export class ToolbarNavigationComponent implements OnInit, OnDestroy {

  sidenavItems$: Observable<SidenavItem[]>;
  currentlyOpen$: Observable<SidenavItem[]>;
  showMoreButtonAfterIndex = 10;

  timer: any;

  skIdLeft: any;

  skIdRight: any;

  skleftimg = '';
  skrightimg = '';

  private _routerEventsSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private globalService: GlobalService
  ) {
    this.skIdLeft = this.globalService.makeid(22);
    this.skIdRight = this.globalService.makeid(22);

  }

  ngOnInit() {

    this.skIdLeft = this.globalService.makeid(22);
    this.skIdRight = this.globalService.makeid(22);

    this.sidenavItems$ = this.store.select(fromRoot.getSidenavItems);
    this.currentlyOpen$ = this.store.select(fromRoot.getSidenavCurrentlyOpen);

    this._routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
      }
    });
    this.timer = interval(1000).subscribe(x => {
      this.positionSkyscrapers();
    });


    /* ADs IMGs */
    this.skleftimg = this.globalService.cdnUrl + "/../y/" + this.globalService.encode('https://lowfuelmotorsport.com/assets/img/prtn2/boxone/LFM%20GIF%20400x1200.gif?arch=' + this.globalService.makeid(22));
    this.skrightimg = this.globalService.cdnUrl + "/../y/" + this.globalService.encode('https://lowfuelmotorsport.com/assets/img/prtn2/syncmesh_side.gif?arch=' + this.globalService.makeid(28));



  }

  ngOnDestroy() {
    this._routerEventsSubscription.unsubscribe();
  }

  positionSkyscrapers() {

    if (this.globalService.issomebodylive()) {
      var top = 212;
    } else {
      var top = 188;
    }

    var d = document.getElementById(this.skIdLeft);
    d.style.position = "fixed";
    d.style.left = (window.innerWidth/2) - 960 + 'px';
    d.style.top = top + 'px';
    //d.style.bottom = 0 + "px";
    //d.style.border = "1px solid green";
    //d.style.height = window.innerHeight - 250 + 'px';
    d.style.display = 'block';
    var d = document.getElementById(this.skIdRight);
    d.style.position = "fixed";
    d.style.left = (window.innerWidth/2) + 700 + 'px';
    d.style.top = top + 'px';
    d.style.bottom = 0 + "px";
    ////d.style.border = "1px solid green";
    //d.style.height = window.innerHeight - 250 + 'px';
    d.style.display = 'block';
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdServiceComponent} from './ad-service.component';
import {MatCardModule} from '@angular/material';


@NgModule({
  declarations: [AdServiceComponent],
  imports: [
    CommonModule,
    MatCardModule
  ],
  exports: [AdServiceComponent]
})
export class AdServiceModule { }

import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { SplashScreenService } from './core/splash-screen.service';
import {AuthenticationService} from './authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import {WINDOW} from './window.providers';

@Component({
  selector: 'elastic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;


  constructor(private splashScreenService: SplashScreenService, private auth: AuthenticationService, private route: ActivatedRoute,
              private router: Router,  private domSanitizer: DomSanitizer, @Inject(WINDOW) private window: Window,
              @Inject(DOCUMENT) private document: Document, private idle: Idle, private cd: ChangeDetectorRef, private sanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry) {

    this.matIconRegistry.addSvgIcon(
      "patreon",
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-mat-icons/patreon.svg'));

    //localStorage.removeItem('currentUser');
    if (!this.auth.isAdmin() && !this.auth.isReko() && this.auth.currentUser.patreon*1 == 0) {
      // set idle parameters
      idle.setIdle(5*60); // how long can they be inactive before considered idle, in seconds
      idle.setTimeout(5*60); // how long can they be idle before considered timed out, in seconds
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      // do something when the user becomes idle
      idle.onIdleStart.subscribe(() => {
        this.idleState = "IDLE";
        window.document.location.href = 'https://lowfuelmotorsport.com/assets/idle.html';
      });
      // do something when the user is no longer idle
      idle.onIdleEnd.subscribe(() => {
        this.idleState = "NOT_IDLE";
        //console.log(`${this.idleState} ${new Date()}`)
        this.countdown = null;
        cd.detectChanges(); // how do i avoid this kludge?
      });
      // do something when the user has timed out
      idle.onTimeout.subscribe(() => this.idleState = "TIMED_OUT");
      // do something as the timeout countdown does its thing
      idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
      console.log("NIX ADMIN ODER REKO, IDLE GEDÖNSE ACTIVE!");
    } else {
      console.log("ADMIN ODER REKO, NIX IDLE!");
    }
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
    console.log('BLA!');
  }



  ngOnInit() {

    this.loadscript();

    this.reset();
    /*
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });




    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    */
    //this.auth.logout();

  }

  ngOnDestroy() {
    /*
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();

     */
  }

  loadscript() {
    let node = document.createElement('script'); // creates the script tag
    node.src = 'https://hb.vntsm.com/v3/live/ad-manager.min.js'; // sets the source (insert url in between quotes)
    node.type = 'text/javascript'; // set the script type
    node.setAttribute('data-site-id', '65e07f2e9105a208f7635fd8');
    node.setAttribute('data-mode', 'scan');
    node.async = true; // makes script run asynchronously
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '../../global.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private router: Router
  ) { }

  getConversations(): any {

    return this.httpClient.get(this.globalService.apiUrl + '/chat/getConversations', {observe: 'events'});

  }

  deleteConversation(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/chat/deleteConversation/' + id);
  }

  createConversation(receiver): any {

    this.httpClient.get(this.globalService.apiUrl + '/chat/newChat/' + receiver, {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            if (response.body.id) {
              this.router.navigateByUrl("/chat/" + response.body.id);
            }
            break;
        }
      });

  }

  getConversation(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/chat/getConversation/' + id, {observe: 'events'});
  }

  loadNewMessages(id): any {
    return this.httpClient.get(this.globalService.apiUrl + '/chat/getNewMessages/' + id, {observe: 'events'});
  }

  postMessage(id, message): any {
    return this.httpClient.post(this.globalService.apiUrl + '/chat/message/' + id, {message: message},{observe: 'events'});
  }

  checkNewMessages(): any {

    return this.httpClient.get(this.globalService.apiUrl + '/chat/newMessages',{observe: 'events'});

  }
}

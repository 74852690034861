import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../users.service';
import {GlobalService} from '../../../global.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {AuthenticationService} from '../../../authentication.service';

@Component({
  selector: 'elastic-user-data-dialog',
  templateUrl: './user-data-dialog.component.html',
  styleUrls: ['./user-data-dialog.component.scss']
})
export class UserDataDialogComponent implements OnInit {
  searchCtrl: string;
  fileToUpload: File;


  constructor(@Inject(MAT_DIALOG_DATA) public user: any, public usersService: UsersService, public globalService: GlobalService, public userService: UsersService, public cdr: ChangeDetectorRef, public auth: AuthenticationService, private dialogRef: MatDialogRef<UserDataDialogComponent>) { }

  ngOnInit() {

    console.log(this.user);

  }

  handleAvatar(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadAvatar() {
    this.userService.uploadAvatar(this.fileToUpload).subscribe(( response: HttpEvent<any> ) => {
      switch (response.type) {
        case HttpEventType.Response:
          if (response.body.success) {
            this.auth.updateUserCookie();
            this.getUserData();
          }
          this.cdr.detectChanges();
          break;
      }
    });
  }

  getUserData() {

    this.userService.getUserData().subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.user = response.body;
            //console.log(this.user);
            this.auth.updateUserCookie();
            this.cdr.detectChanges();
            //console.log(this.auth.getUserData());
            break;
        }
      });

  }

  updateUserData() {

    this.userService.updateUserData(this.user).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.getUserData();
            this.auth.updateUserCookie();
            this.cdr.detectChanges();
            this.dialogRef.close();
            break;
        }
      });

  }
}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../../global.service';
import {AuthenticationService} from '../../../authentication.service';
import {interval} from 'rxjs';
import {MatDialog} from '@angular/material';
import {LoginwindowComponent} from '../../../pages/loginwindow/loginwindow.component';

@Component({
  selector: 'elastic-toolbar-gamma',
  templateUrl: './toolbar-gamma.component.html',
  styleUrls: ['./toolbar-gamma.component.scss']
})
export class ToolbarGammaComponent implements OnInit {

  @Input() quickpanelOpen: boolean;

  @Output() toggledSidenav = new EventEmitter();
  @Output() toggledQuickpanel = new EventEmitter();

  constructor(private _globalService: GlobalService, private _auth: AuthenticationService, private cdr: ChangeDetectorRef, private dialog: MatDialog) { }

  ngOnInit() {
    interval(1000).subscribe(x => {
      this.cdr.detectChanges();
    });
  }

  toggleSidenav() {
    this.toggledSidenav.emit();
  }

  toggleQuickpanel() {
    this.toggledQuickpanel.emit();
  }

  loggedIn() {
    return this._auth.isLoggedIn();
  }

  login() {
    let url = window.location.href;
    window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
    //window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
  }

  loginDiscord() {
    let url = window.location.href;
    //window.location.href = this._globalService.apiUrl + '/../api/discordlogin?return_to=' + url;
    document.location.href = 'https://discord.com/oauth2/authorize?client_id=782264239854583808&redirect_uri='+ this._globalService.apiUrl +'/discordlogin&response_type=code&scope=identify';

  }

  loginWindow() {
    this.dialog.open(LoginwindowComponent);
  }

  openDiscordLink() {
    window.open(this._globalService.discordlink);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import { environment } from '../environments/environment';
import { WINDOW_PROVIDERS } from './window.providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AgmCoreModule } from '@agm/core';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthGuard} from './guards';
import {AuthenticationService} from './authentication.service';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {SpinnerInterceptor} from './pages/spinner-overlay/spinner.overlay.interceptor';
import {SpinnerOverlayComponent} from './pages/spinner-overlay/spinner-overlay.component';
import {FooterModule} from './pages/footer/footer.module';
import {FooterComponent} from './pages/footer/footer.component';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeUS from '@angular/common/locales/en-US-POSIX';
import localeES from '@angular/common/locales/es';
import {FaqComponent} from './pages/lfmlicense/faq/faq.component';
import { NgxEmojiPickerModule} from 'ngx-emoji-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {PipesModule} from './pipes/pipes.module';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {CategoryModalComponent} from './pages/admin/faq/category-modal/category-modal.component';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTabsModule
} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {FaqEditModalComponent} from './pages/admin/faq/faq-edit-modal/faq-edit-modal.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { AdsenseModule } from 'ng2-adsense';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogModule} from './shared/confirm-dialog/confirm-dialog.module';
import {ToastrModule} from 'ngx-toastr';
import {PaginationModule} from './app-pagination/app-pagination.module';
import {NgIdleModule} from '@ng-idle/core';
import { ImagePopupComponent } from './shared/image-popup/image-popup.component';
import {ImagePopupModule} from './shared/image-popup/image-popup.module';
import {AdSharedServiceService} from './ad-shared-service.service';
import {AdServiceComponent} from './pages/ad-service/ad-service.component';
import {CountdownTimerComponent} from './pages/countdown-timer/countdown-timer.component';
import {CountdownTimerModule} from './pages/countdown-timer/countdown-timer.module';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'lowfuelmotorsport.com' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};


registerLocaleData(localeDE);
registerLocaleData(localeUS);
registerLocaleData(localeES);

@NgModule({
  imports: [
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({appId: 'elastic-ui'}),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
    EffectsModule.forRoot([]),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    AppRoutingModule,
    CoreModule,
    PagesModule,
    RouteHandlerModule,
    FooterModule,
    NgxEmojiPickerModule.forRoot(),
    NgxMatSelectSearchModule,
    PipesModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    SortablejsModule,
    MatListModule,
    MatTabsModule,
    AngularEditorModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4055406748626409',
      adSlot: 4246362659,
    }),
    ConfirmDialogModule,
    PaginationModule,
    ImagePopupModule,
    NgIdleModule.forRoot(),
    CountdownTimerModule
  ],
    providers: [
        WINDOW_PROVIDERS,
        AuthGuard,
        AuthenticationService,
        AdSharedServiceService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
        {
          provide: HTTP_INTERCEPTORS,
          useClass: NoCacheHeadersInterceptor,
          multi: true
        },
        {provide: LOCALE_ID, useValue: navigator.language.includes('de') ? 'de-DE' : 'en-US'}
    ],
    declarations: [AppComponent, CategoryModalComponent, FaqEditModalComponent],
    bootstrap: [AppComponent],
    exports: [
      PipesModule,
      CategoryModalComponent,
      FaqEditModalComponent
    ],
    entryComponents: [SpinnerOverlayComponent, CategoryModalComponent, FaqEditModalComponent, FaqComponent, ConfirmDialogComponent, ImagePopupComponent, CountdownTimerComponent]
})
export class AppModule {}


import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EventsService} from '../../../events/events.service';
import {GlobalService} from '../../../../global.service';
import {AuthenticationService} from '../../../../authentication.service';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'elastic-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit {


  category: any = {
    category_id: 0,
    category_en: '',
    category_de: '',
    sort: 0
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventsService,
    private globalServie: GlobalService, globalService: GlobalService,
    private auth: AuthenticationService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<CategoryModalComponent>,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    if (this.data.category_id*1 != 0) {
      this.category = this.data.category;
      this.cdr.detectChanges();
      console.log(this.category);
    }

  }

  save() {

    this.adminService.saveFaqCategory(this.category).subscribe( (data: any) => {
      if (data) {
        this.dialogRef.close('saved');
      }
    });

  }

}

import {ChangeDetectorRef, Component, Input, HostListener, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'elastic-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss']
})
export class PrizesComponent implements OnInit {
  
  schedule = [];
  innerWidth: number;
  @Input() event_id;
  @Input() event: any;

  constructor() { }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownTimerComponent } from './countdown-timer.component';
import {ExtendedModule} from '@angular/flex-layout';



@NgModule({
    declarations: [CountdownTimerComponent],
    exports: [
        CountdownTimerComponent
    ],
  imports: [
    CommonModule,
    ExtendedModule
  ]
})
export class CountdownTimerModule { }

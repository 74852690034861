import { Injectable } from '@angular/core';
import {AuthenticationService} from '../../authentication.service';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '../../global.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private auth: AuthenticationService, private httpClient: HttpClient, private globalService: GlobalService) { }

  changeName(vorname, nachname, shortname) {
    return this.httpClient.post(this.globalService.apiUrl + '/users/changeMyName', {vorname: vorname, nachname: nachname, shortname: shortname});
  }

  getPermits() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/track_permits');
  }

  getAdminUserData(userid) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/admindata/' + userid);
  }

  redeemCode(code) {
    return this.httpClient.post(this.globalService.apiUrl + '/users/redeemCode', {code: code});
  }

  uploadAvatar(file) {
    const endpoint = this.globalService.apiUrl + '/upload';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', 'userAvatar');
    return this.httpClient
      .post(endpoint, formData, { observe: 'events' });
  }

  unlinkDiscord() {
    return this.httpClient.get(this.globalService.apiUrl + '/removeDiscordLink');
  }

  unlinkPatreon() {
    return this.httpClient.get(this.globalService.apiUrl + '/removePatreonLink');
  }

  getMySeries() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/mySeries', {observe: 'events'});

  }

  checkNotifications() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getNotifications');
  }

  markAllNotificationsAsRead() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/markAllAsRead');
  }

  markAsRead(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/markAsRead/' + id);
  }

  getMyLicenseRuns() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/licenseRuns');

  }

  getUsersPenalties(userid) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserPenalties/' + userid);
  }

  getMyTeam() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/myTeam', {observe: 'events'});

  }

  leaveTeam(teamid) {

    return this.httpClient.post(this.globalService.apiUrl + '/teams/leave/' + teamid, {team_id: teamid}, {reportProgress: false, observe: 'events'});

  }

  deleteTeam(teamid) {

    return this.httpClient.post(this.globalService.apiUrl + '/teams/delete/' + teamid, {team_id: teamid}, {reportProgress: false, observe: 'events'});

  }



  getUserData() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/myData', {observe: 'events'});

  }

  getMyPenalties() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/myPenalties', {observe: 'events'});
  }

  getMyReportOutcomes(limit) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/myReportOutcome?limit=' + limit, {observe: 'events'});
  }

  getUserTrackRecords(userId, carclass) {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserTrackRecords/' + userId + '/' + carclass, {observe: 'events'});

  }

  getUserTrackRecordsByCar(userId, carclass, car, sim) {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserTrackRecords/' + userId + '/' + carclass + '?car_id=' + car + '&sim=' + sim, {observe: 'events'});

  }

  getUserTrackStats(userId, carclass, car, sim) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserTrackStats/' + userId + '?class=' + carclass + '&car=' + car + '&sim=' + sim, {observe: 'events'});
  }

  getUserTeamHistory(userId) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/team_history/' + userId);
  }

  getUserCarStats(userId, carclass) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserCarStats/' + userId + '?class=' + carclass, {observe: 'events'});
  }

  getEloGraphData(id, simId) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getEloGraphData/' + id + '?sim_id=' + simId, {observe: 'events'});
  }

  getSrGraphData(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getSrGraphData/' + id, {observe: 'events'});
  }

  getUsersData(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserData/' + id + '?with_achievements=true', {observe: 'events'});
  }

  getUsersPastRaces(id, start, limit, series) {

    const value = series.toString();

    let event = 'false';
    let season = 'false';

    if (value.indexOf('|') > -1) {
      let str = value.split('|', 2);
      season = str[1];
      event = 'false';
    } else {
      if (value != '') {
        event = value;
      }
    }

    return this.httpClient.get(this.globalService.apiUrl + '/users/getUsersPastRaces/' + id + '?start=' + start + '&limit=' + limit + '&event=' + event + '&season=' + season , {observe: 'events'});
  }

  getUsersStats(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getUserStats/' + id, {observe: 'events'});
  }

  getUsersStatsDetail(id, event, season) {

      return this.httpClient.get(this.globalService.apiUrl + '/users/getUserStats/' + id + '?season=' + season + '&event=' + event, {observe: 'events'});

  }

  getUsersRekoEvents(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/getUserRekoEvents/' + id);
  }

  deleteUser(id) {
    return this.httpClient.post(this.globalService.apiUrl + '/users/delete/' + id,  {});
  }

  requestNameChange(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/admin/reko/requestNameChange/' + id);
  }

  getEventsAndSeasons() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getAllEventsAndSeasons');
  }

  getAllEvents() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getAllEvents');
  }

  updateUserData(user) {

    return this.httpClient.post(this.globalService.apiUrl + '/users/update',  {user}, {reportProgress: false, observe: 'events'});

  }

  getMyUpcomingRaces() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getMyUpcomingRaces', {observe: 'events'});

  }

  getMySignedUpRaces() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getMySignedUpRaces');

  }

  getMyPastRaces() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getMyPastRaces', {observe: 'events'});

  }

  getMyPendingReports() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getMyPendingReports', {observe: 'events'});

  }

  deletePendingReport(report_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/deletePendingReport?id=' + report_id);
  }

  getMyStats() {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getMyStats', {observe: 'events'});

  }

  getUsersEvents(id) {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getUsersEvents/' + id, {observe: 'events'});

  }

  getUsersFavouriteCars(id) {

    return this.httpClient.get(this.globalService.apiUrl + '/users/getUsersFavouriteCars/' + id, {observe: 'events'});

  }

  getAllUsers() {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getAll');
  }

  searchUsers(search) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/getAll?search=' + search);
  }

  checkBroadcast(race_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/checkBroadcast/' + race_id);
  }

  ficken(data) {
    return this.httpClient.post(this.globalService.apiUrl + '/users/saveBroadcast', {data});
  }

  removeBroadcast(race_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/users/removeBroadcast/' + race_id);
  }

}

import { Injectable, Renderer2, ElementRef } from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {audit} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdSharedServiceService {

  constructor(private auth: AuthenticationService) {
    console.log('Venatus Ad Service initialized');
  }
  OnInit(el: ElementRef, renderer: Renderer2) {

    if (this.auth.currentUser.patreon > 0 && (location.hostname != "stage.lowfuelmotorsport.com" && location.hostname != 'localhost')) {
      console.log('No Ad Service provided!');
      return false;
    }

    // Destruction logic
    window.__vm_add = window.__vm_add || [];
    //console.log("Ad shared service OnInit function started");
    //please replace querySelectorAll incase you have a better way of doing the query eg. viewchildren query etc
    const adElements = document.querySelectorAll('Ad');

    //console.log(adElements);

    adElements.forEach((adElement: Element) => {



      const placementId = adElement.getAttribute('placementId') || 'null';
      const isRichMedia = adElement.getAttribute('richMedia') || false;
      const isHybridBanner = adElement.getAttribute('hybridBanner') || false;

      const divElement = renderer.createElement('div');
      renderer.setAttribute(divElement, 'data-id', placementId);
      renderer.setAttribute(divElement, 'class', 'vm-placement');

      if (isRichMedia) {
        renderer.setAttribute(divElement, 'style', `display: none`);
        renderer.setAttribute(divElement, 'ad-type', 'rich-media');
      }

      if (isHybridBanner) {
        renderer.setAttribute(divElement, 'data-display-type', 'hybrid-banner');
        renderer.setAttribute(divElement, 'ad-type', 'hybrid-banner');
      }

      renderer.insertBefore(adElement.parentElement, divElement, adElement);
      window.__vm_add.push(divElement);
      console.log('Venatus : vm_add : ', divElement);

      renderer.removeChild(adElement.parentElement, adElement);
    });
  }

  onDestroy(el: ElementRef) {
    window.__vm_remove = window.__vm_remove || [];
    const adElements = el.nativeElement.querySelectorAll('.vm-placement');
    //remove placement one by one
    adElements.forEach((adElement: Element) => {
      const isRichMedia = adElement.getAttribute('ad-type') === 'rich-media';

      if (isRichMedia) {
        window.__vm_remove_category = ['richmedia_all'];
        console.log('Venatus : vm_remove_category');
      } else {
        window.__vm_remove.push(adElement);
        console.log('Venatus : vm_remove : ', adElement);
      }
      if (
        adElement.parentElement &&
        adElement.parentElement.getAttribute('data-ref') === 'vm-preloader'
      ) {
        adElement.parentElement.remove();
      }
    });
  }
}

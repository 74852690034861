import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NameChangeReqComponent} from './name-change-req.component';
import {MatButtonModule, MatIconModule, MatInputModule} from '@angular/material';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [NameChangeReqComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class NameChangeReqModule { }

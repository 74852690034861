import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RaceDetailModalComponent} from '../events/race-detail-modal/race-detail-modal.component';
import {FaqComponent} from './faq/faq.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'elastic-lfmlicense',
  templateUrl: './lfmlicense.component.html',
  styleUrls: ['./lfmlicense.component.scss']
})
export class LfmlicenseComponent implements OnInit, AfterViewInit {

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) { }

  @Input() license: string;
  @Input() linkActive = true;
  @Input() safety = 0;

  @Input() sr_license: string = '';

  @Input() max_sr = 0;

  @Input() symbol = '';

  @Input() small = false;

  @Input() dontShowRookie = false;

  licensestring: string = '';

  safetycolor = 'red';
  finallicense: string;
  isPlusLicense = false;

  ngOnInit() {

    //this.finallicense = this.license.replace(' ','');
    //this.finallicense = this.finallicense.replace('+','PLUS');

    if (this.safety*1 > 1.00) {
      this.safetycolor = 'red';
      this.cdr.detectChanges();
    }

    if (this.safety*1 > 4.99) {
      this.safetycolor = 'silver';
      this.cdr.detectChanges();
    }

    if (this.safety*1 > 6.50) {
      this.safetycolor = 'white';
      this.cdr.detectChanges();
    }

    if (this.max_sr > 0) {
      this.sr_license = this.getSRLic(this.max_sr);
    }

    if (this.safety > 0 && this.sr_license == '') {
      this.sr_license = this.getSRLic(this.safety);
    }
  }

  ngAfterViewInit(): void {

    this.finallicense = this.license.replace(' ','');
    this.finallicense = this.finallicense.replace('+','PLUS');
    this.finallicense = this.finallicense.replace('.','');

    //console.log(this.finallicense);

    if (this.license.indexOf('+') > -1) {
      this.licensestring = this.license.replace('+','');
      this.isPlusLicense = true;
    } else {
      this.licensestring = this.license;
    }

    if (this.safety*1 > 1.00) {
      this.safetycolor = 'red';
      this.cdr.detectChanges();
    }

    if (this.safety*1 > 4.99) {
      this.safetycolor = 'silver';
      this.cdr.detectChanges();
    }

    if (this.safety*1 > 6.50) {
      this.safetycolor = 'white';
      this.cdr.detectChanges();
    }

    this.cdr.detectChanges();
  }

  showLicenseFaq() {
    this.dialog.open(FaqComponent, {
      disableClose: false, data: {}, minWidth: 400, maxWidth: window.innerWidth
    }).afterClosed().subscribe(result => {

    });



  }

  getSRLic(value) {
    let ret;

    if (value > 0) {
      ret = "F";
    }

    if (value >= 2) {
      ret = "E3";
    }

    if (value >= 2.5) {
      ret = "E2";
    }

    if (value >= 3) {
      ret = "E1";
    }

    if (value >= 3.5) {
      ret = "D3";
    }

    if (value >= 4) {
      ret = "D2";
    }

    if (value >= 4.5) {
      ret = "D1";
    }

    if (value >= 5) {
      ret = "C3";
    }

    if (value >= 5.5) {
      ret = "C2";
    }

    if (value >= 6) {
      ret = "C1";
    }

    if (value >= 6.5) {
      ret = "B3";
    }

    if (value >= 7) {
      ret = "B2";
    }

    if (value >= 7.5) {
      ret = "B1";
    }

    if (value >= 8) {
      ret = "A3";
    }

    if (value >= 8.5) {
      ret = "A2";
    }

    if (value >= 9) {
      ret = "A1";
    }

    if (value >= 9.5) {
      ret = "S";
    }

    if (this.license == "ROOKIE" && value >= 5 && this.max_sr == 0) {
      ret = "D1";
    }

    return ret;

  }

}

import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {GlobalService} from '../../../global.service';
import {UserDataDialogComponent} from '../../../pages/users/user-data-dialog/user-data-dialog.component';
import {MatDialog} from '@angular/material';
import {DOCUMENT} from '@angular/common';
import {DiscordPopupComponent} from '../../../pages/users/discord-popup/discord-popup.component';
import {NameChangeReqComponent} from '../../../pages/users/name-change-req/name-change-req.component';
import {ReportSelfAcceptanceComponent} from '../../../pages/users/report-self-acceptance/report-self-acceptance.component';

@Component({
  selector: 'elastic-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

  isOpen: boolean;

  constructor(private auth: AuthenticationService, private globalService: GlobalService, private dialog: MatDialog, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.auth.updateUserCookie();
    //console.log(this.auth.getUserData());
    //console.log(this.auth.isAdmin());



  }

  //AVOID IMAGE RELOAD FOR SIM RATINGS
  trackBy(index, rating) {
    return rating.sim_id;
  }

  ngAfterViewInit() {

    console.log(this.auth.currentUser);

    if (this.auth.currentUser.darkmode*1 == 1) {
      console.log("DARK MODE!");
      if (!this.isDarkMode()) {
        this.darkMode();
      }
    }



    if (this.auth.currentUser.vorname == '' || this.auth.currentUser.nachname == '' || this.auth.currentUser.origin == '') {
     //if (true) {

      this.dialog.open(UserDataDialogComponent, {
        disableClose: false, data: this.auth.currentUser, minWidth: 800, maxWidth: 800
      }).afterClosed().subscribe(result => {
        this.auth.updateUserCookie();
      });

    }

    if (this.auth.currentUser.discord_popup && !this.auth.popup_seen) {

      console.log("Discord popup!");

      this.dialog.open(DiscordPopupComponent, {
        disableClose: false, data: this.auth.currentUser, minWidth: 800, maxWidth: 800
      }).afterClosed().subscribe(result => {
        this.auth.updateUserCookie();
      });
    }

    if (this.auth.currentUser.name_change_req == 1) {
      this.dialog.open(NameChangeReqComponent, {
        disableClose: true, data: this.auth.currentUser, minWidth: 800, maxWidth: 800
      }).afterClosed().subscribe(result => {
        this.auth.updateUserCookie();
      });
    }

    if (this.auth.currentUser.pending_self_acceptance_reports > 0 && !this.auth.currentUser.name_change_req && !this.globalService.selfAcceptWindowOpen) {
      console.log("REPORTS SIND DA!");

      this.dialog.open(ReportSelfAcceptanceComponent, {
        disableClose: true, data: this.auth.currentUser, minWidth: 800, maxWidth: 1200
      }).afterClosed().subscribe(result => {
        this.auth.updateUserCookie();
      });

    }

  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  darkMode() {
    if (this.document.body.classList.contains('darkMode')) {
      this.document.body.classList.remove('darkMode');
      this.auth.setUserDarkMode(0);
    } else {
      this.document.body.classList.add('darkMode');
      this.auth.setUserDarkMode(1);
    }
  }

  isDarkMode() {
    if (this.document.body.classList.contains('darkMode')) {
      return true;
    } else {
      return false;
    }
  }



}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BroadCastService {
  token: string;
  subject = new Subject<any>();

  constructor(private router: Router) { }

  sendMessage(data) {
    this.subject.next(data);
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  generateObject(data) {
      this.sendMessage("signup");
  }
}

import { CommonModule } from '@angular/common';
import { NgModule} from '@angular/core';

import { MatDialogModule, MatButtonModule } from '@angular/material';
import {ImagePopupComponent} from './image-popup.component';
import {ImagePopupService} from './image-popup.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [
    ImagePopupComponent
  ],
  exports: [ImagePopupComponent],
  entryComponents: [ImagePopupComponent],
  providers: [ImagePopupService]
})
export class ImagePopupModule {
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {SpinnerOverlayService} from './spinner-overlay.service';
import {Router} from '@angular/router';
import {GlobalService} from '../../global.service';


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerOverlayService: SpinnerOverlayService, private router: Router, private globalService: GlobalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let url = req.url;

    if ( url.includes("online-users") || url.includes("latest-vid") || url.includes("getMinifiedSeasonBySim") || url.includes("checkYoutubeBroadcasts") || url.includes("checkBroadcaster") || url.includes("accstatus") || url.includes('getSlots') || url.includes('checkSignUp') || url.includes('Twitch') ||  url.includes('checkTwitch') || url.includes('chat') || url.includes('races/checkData') || (url.includes(this.globalService.apiUrl + '/user') && !url.includes('answerSelfAcceptanceReport')) ) {
      return next.handle(req);
    }
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
    return next.handle(req).pipe(finalize(() => spinnerSubscription.unsubscribe()));


  }
}

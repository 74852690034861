import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLiveComponent } from './dashboard-live.component';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [DashboardLiveComponent],
  exports: [
    DashboardLiveComponent
  ],
    imports: [
        CommonModule,
        FlexModule
    ]
})
export class DashboardLiveModule { }

import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../global.service';

@Component({
  selector: 'elastic-ad-service',
  templateUrl: './ad-service.component.html',
  styleUrls: ['./ad-service.component.scss']
})
export class AdServiceComponent implements OnInit {

  @Input() adpos;

  banners = false;
  data = {
    'banner_url' : '',
    'campaign_id': 0
  };
  constructor(private http: HttpClient, private globalService: GlobalService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    this.getAd(this.adpos);

  }

  getAd(pos) {

    this.http.get(this.globalService.apiUrl + '/a?p=' + btoa(pos)).subscribe( (data: any) => {

      if (data) {
        data.banner_url = atob(data.banner_url);
        data.campaign_id = btoa(data.campaign_id);
        this.banners = true;
        this.data = data;
        this.cdr.detectChanges();
      }

    });

  }

}

import {ChangeDetectorRef, Inject, Injectable} from '@angular/core';
import {WINDOW} from './window.providers';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {interval} from 'rxjs';
import {random} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public sim_id: number;

  public selfAcceptWindowOpen = false;

  private nonInterceptHttp: HttpClient;

  standardServerSettings = {
  };

  channelNames = [
    {
      'channel'   : 'lowfuelmotorsport_en',
      'name'      : 'Low Fuel Motorsport EN',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'lowfuelmotorsport_de',
      'name'      : 'Low Fuel Motorsport DE',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'lowfuelmotorsport_es',
      'name'      : 'Low Fuel Motorsport ES',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'virtualendurancemasters',
      'name'      : 'Virtual Endurance Masters',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'boris_s_official',
      'name'      : 'Boris S.',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'zappizipzap',
      'name'      : 'Zappi',
      'live'      : false,
      'platform'  : "twitch"
    }
    ,
    {
      'channel'   : 'andypedale',
      'name'      : 'Andy Pedale',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'chrisracingtv',
      'name'      : 'ChrisRacingTV',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel'   : 'topracingtv',
      'name'      : 'TopRacingTV',
      'live'      : false,
      'platform'  : "twitch"
    },
    {
      'channel' : 'bentastiic',
      'name'    : 'Bentastiic',
      'live' : false,
      'platform': 'twitch'
    },
    {
      'channel' : 'agent_engel',
      'name'    : 'Agent Engel',
      'live' : false,
      'platform': 'twitch'
    },
    {
      'channel' : 'sirfoch',
      'name'    : 'Sir Foch',
      'live' : false,
      'platform': 'twitch'
    },
    {
      'channel' : 'krodonas',
      'name'    : 'Krodonas',
      'live' : false,
      'platform': 'twitch'
    },
    {
      'channel': 'jannesreinders',
      'name'   : 'Jannes Reinders',
      'live': false,
      'platform': 'twitch'
    },
    {
      'channel': 'maxflorenz',
      'name': 'Max Florenz',
      'live': false,
      'platform': 'twitch'
    }
  ];

  ams2_session_flags = [
    {
      "value" : 2,
      "name" : "FORCE_IDENTICAL_VEHICLES"
    },
    {
      "value" : 8,
      "name" : "ALLOW_CUSTOM_VEHICLE_SETUP"
    },
    {
      "value" : 16,
      "name" : "FORCE_REALISTIC_DRIVING_AIDS"
    },
    {
      "value" : 32,
      "name" : "ABS_ALLOWED"
    },
    {
      "value" : 64,
      "name" : "SC_ALLOWED"
    },
    {
      "value" : 128,
      "name" : "TCS_ALLOWED"
    },
    {
      "value" : 256,
      "name" : "FORCE_MANUAL"
    },
    {
      "value" : 512,
      "name" : "FORCE_SAME_VEHICLE_CLASS"
    },
    {
      "value" : 1024,
      "name" : "FORCE_MULTI_VEHICLE_CLASS"
    },
    {
      "value" : 131072,
      "name" : "FILL_SESSION_WITH_AI"
    },
    {
      "value" : 262144,
      "name" : "MECHANICAL_FAILURES"
    },
    {
      "value" : 524288,
      "name" : "AUTO_START_ENGINE"
    },
    {
      "value" : 1048576,
      "name" : "TIMED_RACE"
    },
    {
      "value" : 4194304,
      "name" : "PASSWORD_PROTECTED"
    },
    {
      "value" : 8388608,
      "name" : "ONLINE_REPUTATION_ENABLED"
    },
    {
      "value" : 16777216,
      "name" : "WAIT_FOR_RACE_READY_INPUT"
    },
    {
      "value" : 33554432,
      "name" : "HAS_RACE_DIRECTOR"
    },
    {
      "value" : 67108864,
      "name" : "HAS_BROADCASTER"
    },
    {
      "value" : 134217728,
      "name" : "PIT_SPEED_LIMITER"
    },
    {
      "value" : 268435456,
      "name" : "PIT_STOP_ERRORS_ALLOWED"
    },
    {
      "value" : 536870912,
      "name" : "DISABLE_DRIVING_LINE"
    },
    {
      "value" : 1073741824,
      "name" : "ANTI_GRIEFING_COLLISIONS"
    },
    {
      "value" : -2147483648,
      "name" : "COOLDOWNLAP"
    }
  ];


  youtubeStreams = [];

  licenseRanks = {
    'ROOKIE' : 1,
    'IRON' : 2,
    'IRON+' : 3,
    'BRONZE' : 4,
    'BRONZE+': 5,
    'SILVER' : 6,
    'SILVER+' : 7,
    'GOLD' : 8,
    'GOLD+': 9,
    'PLATINUM' : 10,
    'DIAMOND': 11,
    'LEGEND' : 12,
    'ALIEN' : 13,
    'HERO' : 14,
    'PACE PIRATE': 15,
    'CEO': 16,
    'KEIN ANFÄNGER': 16,
    'MR. BANHAMMER': 16
  };

  maxRatingPerLic = {
    'ROOKIE':   0,
    'IRON' :    999,
    'IRON+':    1299,
    'BRONZE':   1499,
    'BRONZE+':  1699,
    'SILVER':   1999,
    'SILVER+':  2499,
    'GOLD':     3199,
    'GOLD+':    3999,
    'PLATINUM': 4999,
    'DIAMOND':  5999,
    'LEGEND':   7999,
    'ALIEN':    99999
  };

  minRatingPerLic = {
    'IRON': 0,
    'IRON+': 1000,
    'BRONZE': 1300,
    'BRONZE+': 1500,
    'SILVER': 1700,
    'SILVER+': 2000,
    'GOLD': 2500,
    'GOLD+': 3200,
    'PLATINUM': 4000,
    'DIAMOND': 5000,
    'LEGEND': 6000,
    'ALIEN': 8000
  };

  rf2WeatherMapping = {
    0 : "Sunny",
    1 : "Cloudy",
    2 : "Rain",
    3 : "Default",
    4 : "Random"
  };

  acWeatherMapping = {
    '1_heavy_fog' : "Heavy Fog",
    '2_light_fog' : "Light Fog",
    '3_clear'     : "Clear",
    '4_mid_clear' : "Mid Clear",
    '5_light_clouds' : "Light Clouds",
    '6_mid_clouds' : "Mid Clouds",
    '7_heavy_clouds' : "Heavy Clouds"
  };

  rf2FormationLapMapping = {
    0: "standing start",
    1: "formation lap & standing start",
    2: "lap behind safety car & rolling start",
    3: "track default",
    4: "fast rolling start"
  };


  ams2_vehicle_classes = [
    {
      "value" : 1043219688,
      "name" : "F-3"
    },
    {
      "value" : 1087283261,
      "name" : "LMP2_Gen1"
    },
    {
      "value" : 1101044510,
      "name" : "GT3_Gen2"
    },
    {
      "value" : 1106030381,
      "name" : "F-Ultimate"
    },
    {
      "value" : 1183960633,
      "name" : "StockCarV8_2020"
    },
    {
      "value" : 1214699363,
      "name" : "F-Retro_Gen1"
    },
    {
      "value" : 1217280833,
      "name" : "OldStock"
    },
    {
      "value" : 122211381,
      "name" : "Cat_Academy"
    },
    {
      "value" : 1242038633,
      "name" : "F-V10_Gen2"
    },
    {
      "value" : 1242485147,
      "name" : "KartShifter"
    },
    {
      "value" : 1255629241,
      "name" : "F-Classic_Gen3"
    },
    {
      "value" : 1260887313,
      "name" : "Opala79"
    },
    {
      "value" : 1282629711,
      "name" : "P1Gen2"
    },
    {
      "value" : 1323122160,
      "name" : "GT1"
    },
    {
      "value" : 1342781418,
      "name" : "F-Trainer_A"
    },
    {
      "value" : 1347575036,
      "name" : "P1"
    },
    {
      "value" : 1357714335,
      "name" : "TC60S2"
    },
    {
      "value" : 1553262379,
      "name" : "GT4"
    },
    {
      "value" : 1559064669,
      "name" : "F-Vee"
    },
    {
      "value" : 1574895952,
      "name" : "Hypercars"
    },
    {
      "value" : 1734909113,
      "name" : "Carrera Cup"
    },
    {
      "value" : 1740243009,
      "name" : "GTE"
    },
    {
      "value" : 1754258698,
      "name" : "F-Trainer"
    },
    {
      "value" : 2018873511,
      "name" : "F-USA_Gen1"
    },
    {
      "value" : 2084530285,
      "name" : "F-USA_Gen2"
    },
    {
      "value" : 227192681,
      "name" : "F-Vintage_Gen2"
    },
    {
      "value" : -1989076874,
      "name" : "Kart125cc"
    },
    {
      "value" : -1985650835,
      "name" : "RX"
    },
    {
      "value" : -1983642801,
      "name" : "MiniChallenge"
    },
    {
      "value" : 232301361,
      "name" : "Kartcross"
    },
    {
      "value" : -1774345913,
      "name" : "CopaUno"
    },
    {
      "value" : -1773220174,
      "name" : "CopaClassicFL"
    },
    {
      "value" : -1710882048,
      "name" : "P4"
    },
    {
      "value" : -1689111246,
      "name" : "KartRental"
    },
    {
      "value" : -1614293342,
      "name" : "KartGX390"
    },
    {
      "value" : -1453448982,
      "name" : "StockCar99"
    },
    {
      "value" : -1418553548,
      "name" : "Procar"
    },
    {
      "value" : -1388199464,
      "name" : "Hot Cars"
    },
    {
      "value" : -1372148105,
      "name" : "DPI"
    },
    {
      "value" : 29233678,
      "name" : "StockCarV8_2021"
    },
    {
      "value" : -1358908608,
      "name" : "GT5"
    },
    {
      "value" : -1338470134,
      "name" : "ARC_Cam"
    },
    {
      "value" : -1276839914,
      "name" : "CopaClassicB"
    },
    {
      "value" : -1271013239,
      "name" : "TSICup"
    },
    {
      "value" : -1270088329,
      "name" : "Group A"
    },
    {
      "value" : -1200372575,
      "name" : "Supercars"
    },
    {
      "value" : -1165550561,
      "name" : "CopaFusca"
    },
    {
      "value" : -1097982207,
      "name" : "SuperV8"
    },
    {
      "value" : -1091733035,
      "name" : "StockCarV8_2024"
    },
    {
      "value" : -1070382248,
      "name" : "G40Cup"
    },
    {
      "value" : -1068384799,
      "name" : "F-Classic_Gen4"
    },
    {
      "value" : -1049783369,
      "name" : "StockCarV8_2022"
    },
    {
      "value" : -1039818744,
      "name" : "F-Junior"
    },
    {
      "value" : -1016264564,
      "name" : "TC70S"
    },
    {
      "value" : -962921788,
      "name" : "TC60S"
    },
    {
      "value" : -911791795,
      "name" : "Super Trofeo"
    },
    {
      "value" : -900542257,
      "name" : "F-Hitech_Gen1"
    },
    {
      "value" : 341043806,
      "name" : "SuperKart"
    },
    {
      "value" : -883865111,
      "name" : "F-USA_Gen3"
    },
    {
      "value" : -859240302,
      "name" : "CopaTruck"
    },
    {
      "value" : -837115715,
      "name" : "LMDh"
    },
    {
      "value" : -610085299,
      "name" : "F-Hitech_Gen2"
    },
    {
      "value" : -564539194,
      "name" : "LMP2"
    },
    {
      "value" : -553893888,
      "name" : "P2"
    },
    {
      "value" : 374379774,
      "name" : "Montana"
    },
    {
      "value" : 382290110,
      "name" : "G55Supercup"
    },
    {
      "value" : -403229570,
      "name" : "F-USA_2023"
    },
    {
      "value" : -382267785,
      "name" : "Cat_Superlight"
    },
    {
      "value" : -299585121,
      "name" : "F-V12"
    },
    {
      "value" : -217808338,
      "name" : "StockCarV8_2023"
    },
    {
      "value" : -112887377,
      "name" : "GT3"
    },
    {
      "value" : -72151851,
      "name" : "Cat_Supersport"
    },
    {
      "value" : -37194732,
      "name" : "F-Inter"
    },
    {
      "value" : -22587363,
      "name" : "F-V10_Gen1"
    },
    {
      "value" : -4032086,
      "name" : "F-Reiza"
    },
    {
      "value" : 430049282,
      "name" : "Street"
    },
    {
      "value" : 477739468,
      "name" : "F-Retro_Gen3"
    },
    {
      "value" : 492525831,
      "name" : "StockCarV8"
    },
    {
      "value" : 4952537,
      "name" : "LancerCup"
    },
    {
      "value" : 49926370,
      "name" : "F-Ultimate_Gen2"
    },
    {
      "value" : 520250275,
      "name" : "F-Vintage_Gen1"
    },
    {
      "value" : 538991900,
      "name" : "Cat620R"
    },
    {
      "value" : 6496832,
      "name" : "Opala86"
    },
    {
      "value" : 719172364,
      "name" : "F-Retro_Gen2"
    },
    {
      "value" : 801463365,
      "name" : "GTOpen"
    },
    {
      "value" : 829452122,
      "name" : "P3"
    },
    {
      "value" : 843858811,
      "name" : "F-Classic_Gen2"
    },
    {
      "value" : 869498920,
      "name" : "F-Dirt"
    },
    {
      "value" : 891493676,
      "name" : "SprintRace"
    },
    {
      "value" : 913480074,
      "name" : "F-Classic_Gen1"
    },
    {
      "value" : 995310913,
      "name" : "Group C"
    }
  ];

  sims = [
    {
      'sim_id' : 1,
      'simulation': 'Assetto Corsa Competizione',
      'logo_url': '/assets/img/sims/acc.png',
      'classes': ['GT3', 'GT2', 'GT4', 'CUP', 'TCX', 'CHL']
    },

    {
      'sim_id': 2,
      'simulation': 'rFactor 2',
      'logo_url': '/assets/img/sims/rf2.png',
      'classes': ['LMH', 'LMP2', 'GTE', 'GT3', 'BTCC', 'GT4', 'F3', 'Porsche 992 GT3 Cup', 'USF 2000', 'Class 1', 'Dallara IR18', 'Clio RS Cup V']
    },

    {
      'sim_id': 3,
      'simulation': 'Assetto Corsa',
      'logo_url': '/assets/img/sims/ac.png',
      'classes': ['LMDh', 'LMH', 'LMP2', 'GTE', 'GT3', 'GT4', 'F3', 'TCR', 'LMP3', 'MX5 Cup', 'PCUP', 'M2CS', 'VRC Tourers', 'Clio Cup', 'CF-GT3', 'TTVLN', 'VRC FA23', 'SR3', 'GTR-Cup', 'F2004', 'Tatuus FT-60', 'VRC FA24']
      //'classes': ['LMP3', 'MX5 Cup']
    },

    {
      'sim_id': 4,
      'simulation': 'Automobilista 2',
      'logo_url': '/assets/img/sims/ams2.png',
      'classes': [
        'ARC_Cam',
        'Carrera Cup',
        'Cat620R',
        'Cat_Academy',
        'Cat_Superlight',
        'Cat_Supersport',
        'CopaClassicB',
        'CopaClassicFL',
        'CopaFusca',
        'CopaTruck',
        'CopaUno',
        'DPI',
        'F-3',
        'F-Classic_Gen1',
        'F-Classic_Gen2',
        'F-Classic_Gen3',
        'F-Classic_Gen4',
        'F-Dirt',
        'F-Hitech_Gen1',
        'F-Hitech_Gen2',
        'F-Inter',
        'F-Junior',
        'F-Reiza',
        'F-Retro_Gen1',
        'F-Retro_Gen2',
        'F-Retro_Gen3',
        'F-Trainer',
        'F-Trainer_A',
        'F-Ultimate',
        'F-Ultimate_Gen2',
        'F-USA_2023',
        'F-USA_Gen1',
        'F-USA_Gen2',
        'F-USA_Gen3',
        'F-V10_Gen1',
        'F-V10_Gen2',
        'F-V12',
        'F-Vee',
        'F-Vintage_Gen1',
        'F-Vintage_Gen2',
        'G40Cup',
        'G55Supercup',
        'Group A',
        'Group C',
        'GT1',
        'GT3',
        'GT3_Gen2',
        'GT4',
        'GT5',
        'GTE',
        'GTOpen',
        'Hot Cars',
        'Hypercars',
        'Kart125cc',
        'Kartcross',
        'KartGX390',
        'KartRental',
        'KartShifter',
        'LancerCup',
        'LMDh',
        'LMP2',
        'MiniChallenge',
        'Montana',
        'OldStock',
        'Opala79',
        'Opala86',
        'P1',
        'P1Gen2',
        'P2',
        'P3',
        'P4',
        'Procar',
        'RX',
        'SprintRace',
        'StockCar99',
        'StockCarV8',
        'StockCarV8_2020',
        'StockCarV8_2021',
        'StockCarV8_2022',
        'StockCarV8_2023',
        'StockCarV8_2024',
        'Street',
        'Super Trofeo',
        'Supercars',
        'SuperKart',
        'SuperV8',
        'TC60S',
        'TC60S2',
        'TC70S',
        'TSICup']
      //'classes': ['LMP3', 'MX5 Cup']
    },

    {
      'sim_id': 5,
      'simulation': 'iRacing',
      'logo_url': '/assets/img/sims/iracing.png',
      'classes': ["GTP","GTD","Toyota GR86","NASCAR 2022 NextGen","NASCAR Truck"]
    }
  ];



  server_region_mapping = {
    'EU' : 'eu.svg',
    'EU-2' : 'eu.svg',
    'EU-3' : 'eu.svg',
    'NA' : 'us.svg',
    'APAC': 'au.svg'
  };

  server_region_mapping2 = {
    'EU' : 'Europe / Falkenstein',
    'EU-2' : 'Europe / Falkenstein',
    'EU-3' : 'Europe / Falkenstein',
    'NA' : 'USA / Atlanta',
    'APAC': 'Japan / Tokio'
  };

  licenses = ['ROOKIE', 'IRON', 'IRON+', 'BRONZE', 'BRONZE+', 'SILVER', 'SILVER+', 'GOLD', 'GOLD+', 'PLATINUM', 'DIAMOND', 'LEGEND', 'ALIEN'];

  carmapping: any;

  classMapping: any;

  contentTypeMapping: any;

  driverCategoryMapping: any;

  discordlink: string;

  timezone: any;

  timezoneOffset: any;

  apiUrl: string;
  apiUrl2: string;
  apiUrl3: string;
  cdnUrl: string;

  background = {
    'GT3' : {
      'background' : 'blue',
      'color' : 'white'
    },
    'GTD' : {
      'background' : 'blue',
      'color' : 'white'
    },
    'GTP' : {
      'background' : 'black',
      'color' : 'white'
    },
    'GT2' : {
      'background' : 'red',
      'color' : 'white'
    },
    'GT4' : {
      'background' : 'black',
      'color' : 'white'
    },
    'CUP' : {
      'background' : 'green',
      'color' : 'white'
    },
    'ST' : {
      'background' : 'purple',
      'color' : 'white'
    },
    'TCX' : {
      'background' : 'red',
      'color' : 'white'
    },
    'TCR' : {
      'background' : 'red',
      'color' : 'white'
    },
    'BTCC' : {
      'background' : 'red',
      'color' : 'white'
    },
    'GTE' : {
      'background' : 'green',
      'color': 'black'
    },
    'LMP2' : {
      'background' : 'blue',
      'color' : 'white'
    },
    'Toyota GR86' : {
      'background' : 'grey',
      'color' : 'white'
    },
    'NASCAR 2022 NextGen' : {
      'background' : 'blue',
      'color' : 'white'
    },
    "NASCAR Truck": {
      'background' : 'darkblue',
      'color' : 'white'
    }
  };

  serverstatus = {
    'status' : 1,
    'text' : "ACC lobby servers are ONLINE!"
  };

  onlinedrivers = 0;

  countries = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'England', code: 'GB-ENG'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montenegro', code: 'ME'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'North Macedonia', code: 'MK'},
    {name: 'Northern Ireland', code: 'GB-NIR'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Scotland', code: 'GB-SCT'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia', code: 'RS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Türkiye', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wales', code: 'GB-WLS'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ];

  language = navigator.language.includes('de') ? 'german' : 'english';

  urlPattern = '(https?://)?([\\\\da-z.-]+)\\\\.([a-z.]{2,6})[/\\\\w .-]*/?';

  now: any;
  simucube_start: any;
  simucube_end: any;

  constructor(@Inject(WINDOW) private window: Window, private httpClient: HttpClient, @Inject(DOCUMENT) private document: Document, private handler: HttpBackend) {

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.nonInterceptHttp = new HttpClient(handler);

    if (this.getHostname() === 'localhost') {
      this.apiUrl = 'http://lowfuelmotorsport.local/api';
      this.apiUrl2 = 'http://lowfuelmotorsport.local/api';
      this.apiUrl3 = 'https://api.lowfuelmotorsport.de/api';
      //this.apiUrl = 'http://api.lowfuelmotorsport.de/api';
      //this.apiUrl2 = 'http://api.lowfuelmotorsport.de/api';
      //this.apiUrl3 = 'http://api.lowfuelmotorsport.de/api';
      this.cdnUrl = 'https://cdn.raceit.gg/api';
    } else {
      if (this.getHostname().indexOf('stage') != -1) {
        this.apiUrl = 'https://stage-api.lowfuelmotorsport.com/api';
        this.apiUrl2 = 'https://stage-api.lowfuelmotorsport.com/api';
        this.apiUrl3 = 'https://api.lowfuelmotorsport.de/api';
        this.cdnUrl = 'https://cdn.raceit.gg/api';
      } else {
        this.apiUrl = 'https://api3.lowfuelmotorsport.com/api';
        this.apiUrl2 = 'https://api3.lowfuelmotorsport.com/api';
        this.apiUrl3 = 'https://api.lowfuelmotorsport.de/api';
        this.cdnUrl = 'https://cdn.raceit.gg/api';
      }

    }

    this.now = new Date();
    this.simucube_start = new Date("2023-11-23T23:59:59+0100");
    this.simucube_end   = new Date("2023-11-27T23:59:59+0100");


    this.carmapping = {
      '0' : 'porsche.png',
      '1' : 'mercedes.png',
      '2' : 'ferrari.png',
      '3' : 'audi.png',
      '4' : 'lamborghini.png',
      '5' : 'mclaren.png',
      '6' : 'nissan.png',
      '7' : 'bmw.png',
      '8' : 'bentley.png',
      '9' : 'porsche.png',
      '10': 'nissan.png',
      '11': 'bentley.png',
      '12': 'aston_martin.png',
      '13': 'lamborghini.png',
      '14': 'jaguar.png',
      '15': 'lexus.png',
      '16': 'lamborghini.png',
      '17': 'honda.png',
      '18': 'lamborghini.png',
      '19': 'audi.png',
      '20': 'aston_martin.png',
      '21': 'honda.png',
      '22': 'mclaren.png',
      '23': 'porsche.png',
      '25': 'mercedes.png',
      '26': 'ferrari.png',
      '27': 'bmw.png',
      '28': 'porsche.png',
      '29': 'lamborghini.png',
      '30': 'bmw.png',
      '31': 'audi.png',
      '32': 'ferrari.png',
      '33': 'lamborghini.png',
      '34': 'porsche.png',
      '35': 'mclaren.png',
      '36': 'ford.png',
      '24': 'ferrari.png',
      '50': 'alpine.png',
      '51': 'aston_martin.png',
      '52': 'audi.png',
      '53': 'bmw.png',
      '55': 'chevrolet.png',
      '56': 'ginetta.png',
      '57': 'ktm.png',
      '58': 'maserati.png',
      '59': 'mclaren.png',
      '60': 'mercedes.png',
      '61': 'porsche.png',
      '80': 'audi.png',
      '82': 'ktm.png',
      '83': 'maserati.png',
      '84': 'mercedes.png',
      '85': 'porsche.png',
      '86': 'porsche.png',
      '10000' : 'alpine.png',
      '10001' : 'porsche.png',
      '1001': 'aston_martin.png',
      '1002': 'porsche.png',
      '1003': 'bmw.png',
      '1005': 'corvette.png',
      '1006': 'ferrari.png',
      '1007': 'oreca.png',
      '1008': 'ligier.png',
      '1009': 'ford.png',
      '1010': 'hyundai.png',
      '1011': 'infiniti.png',
      '1012': 'toyota.png',
      '1013': 'aston_martin.png',
      '1014': 'audi.png',
      '1015': 'audi.png',
      '1016': 'ferrari.png',
      '1017': 'bentley.png',
      '1018': 'bmw.png',
      '1019': 'bmw.png',
      '1020': 'corvette.png',
      '1022': 'ferrari.png',
      '1024': 'mclaren.png',
      '1025': 'mercedes.png',
      '1026': 'porsche.png',
      '1028': 'bmw.png',
      '1029': 'tatuus.png',
      '10003': 'vauxhall.png',
      '10002': 'porsche.png',
      '10004': 'honda.png',
      '10005': 'stockcar.png',
      '10006': 'vanwall.png',
      '10007': 'cupra.png',
      '10008': 'bmw.png',
      '10009': 'dallara.png',
      '10010': 'renault.png',
      '10011': 'usf2000.png',
      '30000': 'mazda.png',
      '30001': 'duqueine.png',
      '30002': 'f3rss.png',
      '30003': 'cupra.png',
      '30004': 'peugeot.png',
      '30005': 'toyota.png',
      '30006': 'ferrari.png',
      '30007': 'porsche.png',
      '30008': 'bmw.png',
      '30009': 'acura.png',
      '30010': 'cadillac.png',
      '30011': 'oreca.png',
      '30015': 'corvette.png',
      '30016': 'porsche.png',
      '30017': 'acura.png',
      '30018': 'porsche.png',
      '30019': 'bmw.png',
      '30020': 'cadillac.png',
      '30021': 'ferrari.png',
      '30035': 'porsche.png',
      '30036': 'audi.png',
      '30037': 'mercedes.png',
      '30038': 'lamborghini.png',
      '30039': 'ferrari.png',
      '30050': 'porsche.png',
      '30051': 'bmw.png',
      '30022': 'porsche.png',
      '30023': 'toyota.png',
      '30024': 'mercedes.png',
      '30025': 'ford.png',
      '30026': 'bmw.png',
      '30027': 'alpine.png',
      '30028': 'aston_martin.png',

      '30066': 'renault.png',
      '30065': 'Vorax.png',
      '30064': 'Renoir.png',
      '30063': 'Gojira.png',
      '30062': 'Fortix.png',
      '30061': 'Pageau.png',
      '30060': 'rss-mercer.png',
      '30059': 'rss-lux.png',
      '30058': 'rss-lanzo-evo2.png',
      '30057': 'rss-lanzo.png',
      '30056': 'rss-bayer.png',
      '30055': 'rss-akuro.png',
      '30054': 'rss-aero.png',
      '30053': 'urd.png',
      '30052': 'rss-bayer-hybrid.png',
      '30067': 'vrc-fa.png',
      '30068': 'ferrari.png',
      '30069': 'audi.png',
      '30070': 'ginetta.png',
      '30071': 'saleen.png',
      '30072': 'sin.png',
      '30073': 'panoz.png',
      '30074': 'chevrolet.png',
      '30075': 'radical.png',
      '30076': 'nissan.png',
      '30077': 'cadillac.png',
      '30078': 'hyundai.png',
      '30079': 'cupra.png',
      '30080': 'renault.png',

      //KS GT3 ADDITION
      '30081': 'nissan.png',
      '30082': 'bmw.png',
      '30083': 'mclaren.png',

      //AMS2
      //LMDh
      '40102': 'porsche.png',
      '40010': 'cadillac.png',
      '40003': 'bmw.png',
      '40216': 'lamborghini.png',
      '40218': 'alpine.png',

      //GT3_Gen2
      '40111': 'mclaren.png',
      '40104': 'porsche.png',
      '40100': 'mercedes.png',
      '40097': 'bmw.png',
      '40211': 'audi.png',
      '40212': 'lamborghini.png',
      '40217': 'corvette.png',

      //LMP3 (P1_Gen3)
      '40213': 'ligier.png',

      //Group A
      '40002': 'bmw.png',
      '40004': 'mercedes.png',
      '40214': 'audi.png',

      //GT4
      '40084': 'ginetta.png',
      '40096': 'bmw.png',
      '40101': 'mercedes.png',
      '40108': 'porsche.png',
      '40109': 'mclaren.png',
      '40112': 'chevrolet.png',

      //V8
      '40139': 'superv8.png',

      //GT ONE
      '40113': 'mclaren.png',
      '40114': 'mercedes.png',
      '40115': 'porsche.png',
      '40116': 'nissan.png',

      //PCUP
      '40107': 'porsche.png',
      '40106': 'porsche.png',

      //MINI
      '40008': 'mini.png'

    };

    this.classMapping = {
      '0': 'PRO',
      '1': 'PRO',
      '2': 'AM',
      '3': 'SILVER'
    };

    this.driverCategoryMapping = {
      '3' : 'PRO',
      '2' : 'PRO',
      '1' : 'SILVER',
      '0' : 'AM'
    };

    this.contentTypeMapping = {
      '0': 'Base Content',
      '1': 'DLC',
      '2': 'Mod'
    };

    this.discordlink = 'https://discord.gg/lowfuelmotorsport';

    this.standardServerSettings[1] = {
      "server_settings": {
        "event": {
          "file": "event",
          "data": {
            "track": "",
            "preRaceWaitingTimeSeconds": 240,
            "postRaceSeconds": 3600,
            "sessionOverTimeSeconds": 180,
            "ambientTemp": 20,
            "cloudLevel": 0.0,
            "rain": 0.0,
            "weatherRandomness": 3,
            "simracerWeatherConditions": 0,
            "isFixedConditionQualification": 0,
            "sessions": [
              {
                "hourOfDay": 12,
                "dayOfWeekend": 1,
                "timeMultiplier": 1,
                "sessionType": "P",
                "sessionDurationMinutes": 2
              },
              {
                "hourOfDay": 12,
                "dayOfWeekend": 3,
                "timeMultiplier": 1,
                "sessionType": "Q",
                "sessionDurationMinutes": 10
              },
              {
                "hourOfDay": 12,
                "dayOfWeekend": 3,
                "timeMultiplier": 1,
                "sessionType": "R",
                "sessionDurationMinutes": 30
              }
            ],
            "configVersion": 1
          }
        },
        "assistRules": {
          "file": "assistRules",
          "data": {
            "disableIdealLine": 1,
            "disableAutosteer": 1,
            "stabilityControlLevelMax": 0,
            "disableAutoPitLimiter": 1,
            "disableAutoGear": 1,
            "disableAutoClutch": 0,
            "disableAutoEngineStart": 0,
            "disableAutoWiper": 0,
            "disableAutoLights": 0
          }
        },
        "eventRules": {
          "file": "eventRules",
          "data": {
            "qualifyStandingType": 1,
            "pitWindowLengthSec": -1,
            "driverStintTimeSec": 0,
            "mandatoryPitstopCount": 0,
            "maxTotalDrivingTime": 0,
            "maxDriversCount": 2,
            "tyreSetCount": 50,
            "isRefuellingAllowedInRace": true,
            "isRefuellingTimeFixed": false,
            "isMandatoryPitstopRefuellingRequired": false,
            "isMandatoryPitstopTyreChangeRequired": false,
            "isMandatoryPitstopSwapDriverRequired": false
          }
        },
        "settings": {
          "file": "settings",
          "data": {
            "serverName": "",
            "adminPassword": "",
            "password": "",
            "spectatorPassword": "",
            "centralEntryListPath": null,
            "carGroup": "GT3",
            "trackMedalsRequirement": 0,
            "safetyRatingRequirement": 0,
            "racecraftRatingRequirement": 0,
            "maxCarSlots": 50,
            "isRaceLocked": 0,
            "isLockedPrepPhase": 0,
            "shortFormationLap": 0,
            "dumpLeaderboards": 1,
            "dumpEntryList": 1,
            "randomizeTrackWhenEmpty": 0,
            "allowAutoDQ": 0,
            "formationLapType": 3,
            "configVersion": 1
          }
        }
      }
    };

    this.standardServerSettings[4] = {"password":"lfm","servername":"lowfuelmotorsport.com | LFMR |","default":{"PracticeLength":5,"QualifyLength":10,"RaceLength":20,"Flags":["ALLOW_CUSTOM_VEHICLE_SETUP","FORCE_REALISTIC_DRIVING_AIDS","TIMED_RACE","AUTO_START_ENGINE","PIT_SPEED_LIMITER","FORCE_MANUAL","PASSWORD_PROTECTED","DISABLE_DRIVING_LINE","WAIT_FOR_RACE_READY_INPUT","ABS_ALLOWED","TCS_ALLOWED"],"AutoAdvanceSession":1,"DamageType":"FULL","DamageScale":"MAX","TireWearType":"STANDARD","FuelUsageType":"STANDARD","PenaltiesType":"FULL","AllowedViews":"Any","ManualPitStops":1,"PitWhiteLinePenalty":1,"DriveThroughPenalty":1,"AllowedCutsBeforePenalty":3,"ManualRollingStarts":1,"RaceRollingStart":1,"FullCourseYellows":1,"RaceDateHour":16,"RaceDateYear":2024,"RaceDateMonth":6,"RaceDateDay":14,"VehicleClassId":"GT4","MultiClassSlots":0,"MultiClassSlot1":"GT3_Gen2","MultiClassSlot2":"GT3_Gen2","MultiClassSlot3":"GT3_Gen2","PracticeWeatherSlots":1,"PracticeWeatherSlot1":"LightCloud","PracticeWeatherSlot2":"Clear","PracticeWeatherSlot3":"Clear","PracticeWeatherSlot4":"Clear","QualifyWeatherSlots":1,"QualifyWeatherSlot1":"LightCloud","QualifyWeatherSlot2":"Clear","QualifyWeatherSlot3":"Clear","QualifyWeatherSlot4":"Clear","RaceWeatherSlots":2,"RaceWeatherSlot1":"Clear","RaceWeatherSlot2":"Overcast","RaceWeatherSlot3":"Clear","RaceWeatherSlot4":"Clear","RaceMandatoryPitStops":0,"RaceMandatoryPitStopsMinTyres":0,"RaceFormationLap":0,"QualifyPrivateSession":0,"ServerControlsVehicle":0,"VehicleModelId":0,"RaceScheduledFullCourseYellow":0,"DisablePitstopRefuelling":0},"rotation":[{"TrackId":"Kyalami_2019"}]};


    this.standardServerSettings[5] = {
      "password": "",
      "servername": "",
      "practiceStartHour": 12,
      "practiceLength": 0,
      "qualifyingStartHour": 13,
      "qualifyingLength": 0,
      "raceStartHour": 14,
      "raceLength": 0,
      "raceLengthMetric": "Time",
      "qualifyingType": "Open",
      "weather": "",
      "startProcedure": "Rolling Start"
    }

  }

  getSelfAcceptanceReports() {
    if (this.isLoggedIn()) {
      return this.httpClient.get(this.apiUrl + '/users/getSelfAcceptanceReports');
    }
  }

  answerSelfAcceptanceReport(report_id, answer, text) {
    if (this.isLoggedIn()) {
      return this.httpClient.post(this.apiUrl + '/users/answerSelfAcceptanceReport', {report_id: report_id, answer: answer, text: text});
    }
  }

  openNewTab(string) {
    window.open(string);
  }

  setFavSim(sim_id) {
    if (this.isLoggedIn()) {
      this.httpClient.get(this.apiUrl + '/users/setFavSim?sim_id=' + sim_id).subscribe( (data) => {
        this.sim_id = sim_id;
      });
    }
  }



  getActiveSim() {
    let y = 1;
    this.sims.forEach((sim, index) => {
      if (this.sim_id == sim.sim_id) {
        y = sim.sim_id;
      }
    });

    return y;
  }

  isDarkMode() {
    if (this.document.body.classList.contains('darkMode')) {
      return true;
    } else {
      return false;
    }
  }

  getSimuCubeSlogan() {

    var slogans = [
      "- Make the best out of your race. –Simucube",
      "- Gear up and good luck in your race! –Simucube",
      "- Make all the difference - best of luck in your race! –Simucube",
      "- You've got this - gear up for your race! –Simucube",
      "- Gear up and may the race be in your favor! –Simucube",
      "- Gear up and give it your all - don't let doubts hold you back in your race! -Simucube"
    ];

    return slogans.length ? slogans[Math.floor(Math.random() * slogans.length)] : undefined;

  }

  base64encode(string) {
    const bla = btoa(string);
    return bla;
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  getDashboardStats(): any {

    return this.httpClient.get(this.apiUrl + '/dashboard/stats', {observe: 'events'});

  }

  getLiveryToolVersion(): any {

    return this.httpClient.get(this.apiUrl + '/liveries/getversion');

  }

  getReportingReasons(): any {
    return this.httpClient.get(this.apiUrl + '/getReportingReasons', {observe: 'events'});
  }

  getReportingReasons2(): any {
    return this.httpClient.get(this.apiUrl + '/getReportingReasons2', {observe: 'events'});
  }
  sendReport(report, driver, race): any {

    return this.httpClient.post(this.apiUrl + '/race/' + race + '/sendReport', {report: report, driver: driver}, {reportProgress: false, observe: 'events'});

  }

  getDashboardUpcomingRaces(): any {

    return this.httpClient.get(this.apiUrl + '/dashboard/upcomingSessions', {observe: 'events'});

  }

  getUserTimeZone() {
    if (!this.timezone) {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this.timezone;
  }
  getUserTimeZoneOffset() {
    let d = new Date();
    if (!this.timezoneOffset) {
      this.timezoneOffset = d.getTimezoneOffset();
    }
    return this.timezoneOffset;
  }

  getCountryName(code) {
    let result = "";
    this.countries.forEach(function (item) {
      if (item.code.toUpperCase() === code.toUpperCase()) {
        result = item.name;
      }
    }, this);
    return result;
  }

  getDashboardNews() {

    return this.httpClient.get(this.apiUrl + '/dashboard/news', {observe: 'events'});

  }

  uploadTeamScreenshot(file, id) {
    const endpoint = this.apiUrl + '/upload';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('team_car_id', id.toString());
    formData.append('type', 'teamLiveryScreenshot');
    console.log(formData);
    return this.httpClient.post(endpoint, formData, { observe: 'events' });
  }

  uploadTeamLivery(file, id) {
    const endpoint = this.apiUrl + '/upload';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('team_car_id', id.toString());
    formData.append('type', 'teamLiveryZip');
    console.log(formData);
    return this.httpClient.post(endpoint, formData, { observe: 'events' });
  }

  uploadSingleEventLivery(file, id, event) {
    const endpoint = this.apiUrl + '/upload';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('user_id', id.toString());
    formData.append('event_id', event.event_id.toString());
    formData.append('type', 'singleEventLiveryZip');
    console.log(formData);
    return this.httpClient.post(endpoint, formData, { observe: 'events' });
  }

  getLicenseDriveData() {
    return this.httpClient.get(this.apiUrl + '/licenseDriveData');
  }


  openLink(link) {
    window.open(link, '_blank');
  }

  checkAccStatus() {
    return this.httpClient.get(this.apiUrl + '/accstatus');
  }

  getOnlineDrivers() {
    return this.httpClient.get(this.apiUrl + '/online-users');
  }

  getTeamLiveries(team_id) {
    return this.httpClient.get(this.apiUrl + '/liveries/team/' + team_id);
  }

  setDefaultLivery(uuid) {
    return this.httpClient.get(this.apiUrl + '/liveries/setDefault/' + uuid);
  }
  getTeamLiveries2(team_id) {
    return this.httpClient.get(this.apiUrl + '/liveries/team/' + team_id);
  }

  deleteLivery(uuid) {
    return this.httpClient.get(this.apiUrl + '/liveries/delete/' + uuid);
  }

  getUsersLiveries(sim = -1) {
    return this.httpClient.get(this.apiUrl + '/liveries/user?sim=' + sim);
  }

  getSpecificUsersLiveriesForEvent(userid, eventId) {
    return this.httpClient.get(this.apiUrl + '/liveries/user?userid=' + userid + '&eventId=' + eventId);
  }

  getTeamsPendingEventLiveries(p_id, eventId) {
    return this.httpClient.get(this.apiUrl + '/liveries/event_pending?p_id=' + p_id + '&eventId=' + eventId);
  }

  getUserPendingEventLiveries(user_id, eventId) {
    return this.httpClient.get(this.apiUrl + '/liveries/event_pending?user_id=' + user_id + '&eventId=' + eventId);
  }

  getSpecificUsersLiveries(userid) {
    return this.httpClient.get(this.apiUrl + '/liveries/user?userid=' + userid);
  }

  getUsersLiveriesAll() {
    return this.httpClient.get(this.apiUrl + '/liveries/user/all');
  }

  getJoinLink(race_id) {

    return this.httpClient.get(this.apiUrl + '/race/' + race_id + '/getJoinData');

  }

  getJoinLink2(race_id, split) {

    return this.httpClient.get(this.apiUrl + '/race/' + race_id + '/getBroadcastJoinData/' + split);

  }

  checkUserCarForEvent(event_id,user_id) {
    return this.httpClient.get(this.apiUrl + "/events/" + event_id + "/checkUserCar/" + user_id);
  }

  issomebodylive() {
    let live = false;
    this.channelNames.forEach( (channel: any) => {
      if (channel.live) {
        live = true;
      }
    });
    if (this.youtubeStreams.length > 0) {
      live = true;
    }

    return live;

  }

  getPendingNameChangeRequests() {
    return this.httpClient.get( this.apiUrl + "/admin/reko/getPendingNameChanges");
  }

  getFinishedNameChangeRequests() {
    return this.httpClient.get( this.apiUrl + "/admin/reko/getFinishedNameChanges");
  }

  getWeatherSnapshotForAccTrack(track) {
    return this.httpClient.get( this.apiUrl + "/getWeatherSnapshot?track=" + track);
  }

  checkEligible(min_sr, max_sr, min_license, user_license, user_sr) {
    let unlocked = true;

    if (this.licenseRanks[user_license] >= this.licenseRanks[min_license]) {
      if (user_sr > max_sr && max_sr > 0) {
        unlocked = false;
      }
      if (user_sr < min_sr && min_sr > 0) {
        unlocked = false;
      }
    } else {
      unlocked = false;
    }

    if (user_license == "ROOKIE" && min_license == "ROOKIE") {
      unlocked = true;
    }

    return unlocked;

  }

  getLatestYtVid() {
    return this.httpClient.get( this.apiUrl + "/youtube/latest-vid");
  }

  encode(string: string) {
    return btoa(string);
  }

  getSeasonEventInfo(eventId) {
    return this.httpClient.get( this.apiUrl + "/v2/seasons/eventInfo/" + eventId);
  }

  getCarClasses(sim_id) {
    return this.httpClient.get(this.apiUrl + '/v2/cars/carclasses/' + sim_id );
  }


  getSrLicFromValue(value) {

    var ret = 'F';

    if (value >= 2) {
      ret = 'E3';
    }
    if (value >= 2.5) {
      ret = 'E2';
    }
    if (value >= 3) {
      ret = 'E1';
    }
    if (value >= 3.5) {
      ret = 'D3';
    }
    if (value >= 4) {
      ret = 'D2';
    }
    if (value >= 4.5) {
      ret = 'D1';
    }
    if (value >= 5) {
      ret = 'C3';
    }
    if (value >= 5.5) {
      ret = 'C2';
    }
    if (value >= 6) {
      ret = 'C1';
    }
    if (value >= 6.5) {
      ret = 'B3';
    }
    if (value >= 7) {
      ret = 'B2';
    }
    if (value >= 7.5) {
      ret = 'B1';
    }
    if (value >= 8) {
      ret = 'A3';
    }
    if (value >= 8.5) {
      ret = 'A2';
    }
    if (value >= 9) {
      ret = 'A1';
    }
    if (value >= 9.5) {
      ret = 'S';
    }
    return ret;
  }

  makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;

  }

  randomNo() {
    let max = 18;
    let min = 3;
    return Math.random() * (max - min) + min;
  }

}

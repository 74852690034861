import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardSocialsComponent} from './dashboard-socials.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatTooltipModule} from '@angular/material';


@NgModule({
  declarations: [DashboardSocialsComponent],
    imports: [
        CommonModule,
        FlexModule,
        FlexLayoutModule,
        MatTooltipModule
    ],
  exports: [
    DashboardSocialsComponent
  ]
})
export class DashboardSocialsModule { }

import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {GlobalService} from '../../../global.service';

@Component({
  selector: 'elastic-discord-popup',
  templateUrl: './discord-popup.component.html',
  styleUrls: ['./discord-popup.component.scss']
})
export class DiscordPopupComponent implements OnInit {

  constructor(private auth: AuthenticationService, private globalService: GlobalService) { }

  ngOnInit() {
  }

  linkDiscord() {
    document.location.href = 'https://discord.com/oauth2/authorize?client_id=782264239854583808&redirect_uri='+ this.globalService.apiUrl +'/discordaccountlink&response_type=code&scope=identify&state=' + this.auth.getUserId();
  }

  joinDiscord() {
    if (this.auth.currentUser.discord_server_join) {
      window.open(this.auth.currentUser.discord_server_join);
    } else {
      window.open('https://discord.gg/lowfuelmotorsport');
    }
  }

}

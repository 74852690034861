import {ChangeDetectorRef, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {GlobalService} from './global.service';
import {UsersService} from './pages/users/users.service';
import {RaceDetailModalComponent} from './pages/events/race-detail-modal/race-detail-modal.component';
import {MatDialog} from '@angular/material';
import {UserDataDialogComponent} from './pages/users/user-data-dialog/user-data-dialog.component';
import {ReportSelfAcceptanceComponent} from './pages/users/report-self-acceptance/report-self-acceptance.component';

@Injectable()
export class AuthenticationService {

  currentUser: any;

  popup_seen = false;

  constructor(private http: HttpClient, private globalService: GlobalService, private dialog: MatDialog) { }

  login(tokenObject, redirect) {
    localStorage.clear();
    localStorage.setItem('currentUser', JSON.stringify(tokenObject));
    ////console.log(tokenObject);
    this.http.get(this.globalService.apiUrl + '/user', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            tokenObject.userData = response.body;
            localStorage.setItem('currentUser', JSON.stringify(tokenObject));
            console.log(response.body);
            window.location.href=redirect;
            break;
        }
      });


    /*
    return this.http.post<any>(`/users/authenticate`, { username: username, password: password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

        }

        return user;
      }));

     */
  }

  logout() {
    // remove user from local storage to log user out and delete all access tokens

    /*this.http.post(this.globalService.apiUrl + "/logout", {}).subscribe( (data: any) => {
      if (data) {
        if (data.success) {
          localStorage.removeItem('currentUser');
        }
      }
    });*/
    localStorage.removeItem('currentUser');
  }

  isLicenseValid() {
    this.updateUserCookie();
    console.log(this.currentUser.valid_license);
    if (!this.currentUser) {
      this.getUserData();
    }

    if (this.currentUser.valid_license*1 == 0) {
      return false;
    } else {
      return true;
    }

  }

  setUserDarkMode(state) {
    this.http.get(this.globalService.apiUrl + '/darkmode/' + state, {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.updateUserCookie();
            break;
        }
      });
  }

  getUserData() {
    let currentUser;
    if (currentUser = JSON.parse(localStorage.getItem('currentUser'))) {
      this.currentUser = currentUser.userData;
      if(this.currentUser) {
        if (this.currentUser.gameservers == undefined) {
          this.currentUser.gameservers = [];
        }
      }
      return currentUser.userData;
    } else {
      this.currentUser = false;
    }

  }

  isTvBroadcaster() {
    this.getUserData();
    return this.currentUser.is_tv_broadcaster;
  }

  getUserName() {
    this.getUserData();
    return this.currentUser.name;
  }

  getUserElo() {
    this.getUserData();
    return this.currentUser.cc_rating;
  }

  getUserSimElo() {
    this.getUserData();
    return this.currentUser.cc_rating;
  }

  getUserSr() {
    this.getUserData();
    return this.currentUser.safety_rating;
  }

  getUserSrLic() {
    this.getUserData();
    return this.currentUser.sr_license;
  }

  getUserLicense() {
    this.getUserData();
    return this.currentUser.license;
  }

  getUserSimLicense(simId) {
    this.getUserData();
    let lic = "ROOKIE";
    this.currentUser.rating_by_sim.forEach( (sim: any) => {
      if (sim.sim_id == simId) {
        lic = sim.license;
      }
    });

    return lic;

  }

  getUserEloBySim(simId) {
    this.getUserData();
    let elo = 1500;
    this.currentUser.rating_by_sim.forEach( (sim: any) => {
      if (sim.sim_id == simId) {
        elo = sim.rating;
      }
    });

    return elo;
  }

  getAvatar() {
    this.getUserData();
    return this.currentUser.avatar;
  }

  isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    this.getUserData();
    if (this.currentUser) {
      if (this.currentUser.admin == 1) {
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }

  }

  isCommunityAdmin(community_id = 0) {
    this.getUserData();
    if (this.currentUser) {
      if (this.isAdmin()) {
        return true;
      }
      if (community_id != 0) {
        if (this.currentUser.c_a != undefined) {
          // @ts-ignore
          if (this.currentUser.c_a.indexOf(parseInt(community_id,10)) != -1) {
            return true;
          }
        }
      } else {
        if (this.currentUser.c_a != undefined) {
          console.log("C_A is da!");
          console.log(this.currentUser.c_a);
          if (this.currentUser.c_a.length > 0) {
            console.log("C_A length = " + this.currentUser.c_a.length);
            return true;
          }
        }
      }
    }
    return false;
  }

  isEventAdmin(event_id) {
    this.getUserData();
    if (this.currentUser) {

      if (this.isAdmin()) {
        return true;
      }

      if (this.currentUser.event_a != undefined) {
        if (this.currentUser.event_a.indexOf(parseInt(event_id,10)) != -1) {
          //console.log("YES!!");
          //console.log(this.currentUser);
          //console.log(this.currentUser.event_a);
          //console.log(event_id);
          return true;
        }
      }



      if (this.currentUser.id == 798 && event_id == 286) {
        return true;
      }

      if ((this.currentUser.id == 7586 || this.currentUser.id == 7838 || this.currentUser.id == 13428 || this.currentUser.id == 508) && (event_id == 230 || event_id == 236)) {
        return true;
      }

      if ((this.currentUser.id == 7586 || this.currentUser.id == 7838) && (event_id == 157)) {
        return true;
      }

      if (this.currentUser.id == 7586 && (event_id == 93)) {
        return true;
      }

      if (this.currentUser.id == 7586 && (event_id == 110)) {
        return true;
      }

      if (this.currentUser.id == 2661 && (event_id == 89 || event_id == 90 || event_id == 91)) {
        return true;
      }
    }
    console.log("NON!!");
    console.log(this.currentUser);
    console.log(this.currentUser.event_a);
    console.log(event_id);
    return false;
  }

  isReko() {
    this.getUserData();
    if (this.currentUser) {
      return this.currentUser.isReko;
    } else {
      return false;
    }
  }

  isEditor() {
    this.getUserData();
    if (this.currentUser) {
      return this.currentUser.isReko;
    } else {
      return false;
    }
  }

  redirectToLogin() {
    let url = window.location.href;
    window.location.href = this.globalService.apiUrl + '/../auth/steam?return_to=' + url;
  }

  updateUserCookie() {
    this.http.get(this.globalService.apiUrl + '/user', {observe: 'events'}).subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            let tokenObject = JSON.parse(localStorage.getItem('currentUser'));
            if (tokenObject.userData) {
              if (tokenObject.userData.gameservers == undefined) {
                tokenObject.userData.gameservers = [];
              }
            }

            if (tokenObject.userData.valid_license) {
              if (tokenObject.userData.valid_license*1 == 0 && response.body.valid_license*1 == 1) {

                alert('Congratulations! You now have a valid LFM Driver License!');

              }
            }

            tokenObject.userData = response.body;

            localStorage.setItem('currentUser', JSON.stringify(tokenObject));
            this.globalService.sim_id = tokenObject.userData.fav_sim;

            console.log('cookie updated!');
            console.log(JSON.parse(localStorage.getItem('currentUser')));

            if (tokenObject.userData.pending_self_acceptance_reports > 0 && !tokenObject.userData.name_change_req && !this.globalService.selfAcceptWindowOpen) {

              this.dialog.open(ReportSelfAcceptanceComponent, {
                disableClose: true, data: tokenObject.userData, minWidth: 800, maxWidth: 1200
              }).afterClosed().subscribe(result => {
                this.updateUserCookie();
              });

            }
            //window.location.href=redirect;
            break;
        }
      },
      (error => {
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 401:      //login
                  this.logout();
                  break;
              }
            }
          } else {
            console.error("some thing else happened");
          }
      }
      ));
  }

  getUserId() {
    this.getUserData();
    if (this.currentUser) {
      return this.currentUser.id;
    } else {
      return false;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../../global.service';

@Component({
  selector: 'elastic-loginwindow',
  templateUrl: './loginwindow.component.html',
  styleUrls: ['./loginwindow.component.scss']
})
export class LoginwindowComponent implements OnInit {

  constructor(private _globalService: GlobalService) { }

  ngOnInit() {
  }

  steamLogin() {
    let url = window.location.href;
    window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
    //window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
  }

  discordLogin() {
    let url = window.location.href;
    //window.location.href = this._globalService.apiUrl + '/../api/discordlogin?return_to=' + url;
    document.location.href = 'https://discord.com/oauth2/authorize?client_id=782264239854583808&redirect_uri='+ this._globalService.apiUrl +'/discordlogin&response_type=code&scope=identify';

  }

}

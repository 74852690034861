import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import * as ts from 'typescript';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EventsService} from '../../../events/events.service';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '../../../../global.service';
import {AuthenticationService} from '../../../../authentication.service';
import {UsersService} from '../../../users/users.service';

@Component({
  selector: 'elastic-upload-livery',
  templateUrl: './upload-livery.component.html',
  styleUrls: ['./upload-livery.component.scss']
})
export class UploadLiveryComponent implements OnInit {

  livery = {
    'name' : '',
    'carModel' : '',
    'teamId' : 0,
    'hasDecalsFiles' : false,
    'hasSponsorsFiles' : false,
    'accept': false,
    'sim_id': 1,
    'files' : {
    }
  };

  carJson: File;
  screenshot: File;
  decalsJson: File;
  decalsPng: File;
  decalsDDS0: File;
  decalsDDS1: File;
  sponsorsJson: File;
  sponsorsPng: File;
  sponsorsDDS0: File;
  sponsorsDDS1: File;

  liveryDDS: File;

  simulations = [
    {'sim_id': 1, 'name': 'Assetto Corsa Competizione'},
    {'sim_id': 3, 'name': 'Assetto Corsa'}
  ];

  error: false;

  myteam: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public eventsService: EventsService, public auth: AuthenticationService, public cdr: ChangeDetectorRef, public usersService: UsersService,
              public httpClient: HttpClient, public globalService: GlobalService, public dialogRef: MatDialogRef<UploadLiveryComponent>) { }

  ngOnInit() {
    this.livery.teamId = this.data.teamId;
    this.livery.sim_id = this.data.sim;

    console.log(this.data);

    if (this.data.sim) {
      this.livery.sim_id = this.data.sim;
    }
    if (this.data.car_model) {
      this.livery.carModel = this.data.car_model;
    }

    if (this.data.userId) {
      // @ts-ignore
      this.livery.teamId = 0;
      this.usersService.getMyTeam().subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              this.myteam = response.body;
              console.log(this.myteam);
              this.cdr.detectChanges();
              break;
          }
        });
    }

  }

  handleFile(files: FileList, size, file) {
    if ((files.item(0).size / 1024) > size) {
      alert('Your file is bigger than '+size+'kb');
      return;
    } else {
      this[file] = files.item(0);
    }
    console.log(this[file]);
  }

  upload() {
    let valid = true;
    let errors = [];

    if (this.livery.name == '') {
      valid = false;
      errors.push('Please enter a name for the livery!');
    }

    if (this.livery.carModel == '') {
      valid = false;
      errors.push('Please select a car Model!');
    }

    if (!this.screenshot) {
      errors.push('Please upload Screenshot PNG file!!');
      valid = false;
    }

    // ACC
    if (this.livery.sim_id == 1) {
      if (!this.carJson) {
        errors.push("Please upload car.json file!");
        valid = false;
      }

      if (this.livery.hasDecalsFiles) {

        if (!this.decalsJson) {
          errors.push('Please upload decals Json file!');
          valid = false;
        }

        if (!this.decalsPng) {
          errors.push('Please upload decals PNG file!');
          valid = false;
        }

        if (!this.decalsDDS0) {
          errors.push('Please upload decals DDS 0 file!');
          valid = false;
        }

        if (!this.decalsDDS1) {
          errors.push('Please upload decals DDS 1 file!');
          valid = false;
        }

      }

      if (this.livery.hasSponsorsFiles) {

        if (!this.sponsorsJson) {
          errors.push('Please upload sponsors Json file!');
          valid = false;
        }

        if (!this.sponsorsPng) {
          errors.push('Please upload sponsors PNG file!');
          valid = false;
        }

        if (!this.sponsorsDDS0) {
          errors.push('Please upload sponsors DDS 0 file!');
          valid = false;
        }

        if (!this.sponsorsDDS1) {
          errors.push('Please upload sponsors DDS 1 file!');
          valid = false;
        }

      }
    }

    if (this.livery.sim_id == 3) {
      if (!this.liveryDDS) {
        errors.push("Please upload the car skin dds file!");
        valid = false;
      }
    }

    if (!valid) {
      alert(errors);
      return false;
    }

    const formData: FormData = new FormData();


      if (this.carJson) {
        formData.append('carJson', this.carJson, this.carJson.name);
      }

      if (this.decalsPng) {
        formData.append('decalsPng', this.decalsPng, this.decalsPng.name);
        formData.append('decalsDds1', this.decalsDDS1, this.decalsDDS1.name);
        formData.append('decalsDds0', this.decalsDDS0, this.decalsDDS0.name);
        formData.append('decalsJson', this.decalsJson, this.decalsJson.name);
      }

      if (this.sponsorsPng) {
        formData.append('sponsorsPng', this.sponsorsPng, this.sponsorsPng.name);
        formData.append('sponsorsDds1', this.sponsorsDDS1, this.sponsorsDDS1.name);
        formData.append('sponsorsDds0', this.sponsorsDDS0, this.sponsorsDDS0.name);
        formData.append('sponsorsJson', this.sponsorsJson, this.sponsorsJson.name);
      }

      formData.append('screenshot', this.screenshot, this.screenshot.name);

      if (this.liveryDDS) {
        formData.append('liveryDDS', this.liveryDDS, this.liveryDDS.name);
      }

      formData.append('name', this.livery.name);
      formData.append('sim_id', this.livery.sim_id.toString());
      formData.append('team', this.livery.teamId.toString());
      formData.append('carmodel', this.livery.carModel);

      if (this.data.eventupload) {
        formData.append('p_id', this.data.p_id.toString());
        formData.append('event_id', this.data.eventId.toString());
        formData.append('event_user_id', this.data.userId.toString());
      }

    let tokenObject = JSON.parse(localStorage.getItem('currentUser'));

    let abcheaders = {
      'Authorization': 'Bearer ' + tokenObject.accessToken
    };

    this.httpClient.post(this.globalService.apiUrl + '/liveries/upload', formData, {headers: abcheaders}).subscribe((data: any ) => {
      if (data.success) {
        this.dialogRef.close("saved");
      } else {
        this.error = data.message;
      }
    });

  }

}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {ImagePopupComponent} from './image-popup.component';
@Injectable()
export class ImagePopupService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ImagePopupComponent>;

  public open(options: string) {
    this.dialogRef = this.dialog.open(ImagePopupComponent, {
      data: {
        image: options
      }
    });
  }
  public confirmed(): Observable<any> {

    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}

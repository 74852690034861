import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EventsService} from '../../events/events.service';
import {GlobalService} from '../../../global.service';
import {AuthenticationService} from '../../../authentication.service';

@Component({
  selector: 'elastic-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private eventsService: EventsService,
              private globalServie: GlobalService,
              private auth: AuthenticationService,
              public dialogRef: MatDialogRef<FaqComponent>) { }

  ngOnInit() {
  }

}

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {EventsService} from '../events/events.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {interval} from 'rxjs';
import {WINDOW} from '../../window.providers';
import { DomSanitizer } from '@angular/platform-browser';
import {GlobalService} from '../../global.service';

@Component({
  selector: 'elastic-dashboard-live',
  templateUrl: './dashboard-live.component.html',
  styleUrls: ['./dashboard-live.component.scss']
})
export class DashboardLiveComponent implements OnInit {

  borislive = false;
  zappilive = false;
  andypedalelive = false;
  show = true;
  big = false;

  hostname: any = '';

  loader: any;
  constructor(private eventsService: EventsService, private cdr: ChangeDetectorRef, @Inject(WINDOW) private window: Window, private sanitizer: DomSanitizer, private globalService: GlobalService) { }

  channels = ['boris_s_official', 'ZappiZipZap'];



  ngOnInit() {

    this.hostname = this.window.location.hostname;
    console.log(this.hostname);
    this.getData();

    this.loader = interval(300000).subscribe( y => {
      this.getData();
    });
  }

  makeItBig($event) {
    $event.element.height = '300px';
    this.big = true;
  }

  makeItSmall($event) {
    $event.element.height = '80px';
    this.big = false;
  }

  getData() {

    let channelarray = [];

    this.globalService.channelNames.forEach( (channel: any) => {
      channelarray.push(channel.channel);
    });

    this.eventsService.checkBroadcaster().subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:

            response.body.data.forEach((item) => {

              this.globalService.channelNames.forEach( (channel: any) => {
                if (item.user_login.toLowerCase() == channel.channel) {
                  channel.live = true;
                }
              });

            });

            this.cdr.detectChanges();

            this.eventsService.checkYouTubeBroadcaster().subscribe( (data: any) => {
              console.log(data);
              if (data) {
                this.globalService.youtubeStreams = data;
                this.cdr.detectChanges();
              }
            });

            break;
        }
      });

  }


}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {

}

if (location.hostname == "lowfuelmotorsport.com") {

    enableProdMode();

   console.log = function() {};
   console.warn = function() {};
   console.info = function() {};
   console.error = function() {};

}

platformBrowserDynamic().bootstrapModule(AppModule);

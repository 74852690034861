import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EventsService} from '../../../events/events.service';
import {GlobalService} from '../../../../global.service';
import {AuthenticationService} from '../../../../authentication.service';
import {AdminService} from '../../admin.service';
import {SortablejsOptions} from 'ngx-sortablejs';

@Component({
  selector: 'elastic-faq-edit-modal',
  templateUrl: './faq-edit-modal.component.html',
  styleUrls: ['./faq-edit-modal.component.scss']
})
export class FaqEditModalComponent implements OnInit {

  faq: any;

  groupOptions: SortablejsOptions = {
    group: 'testGroup',
    handle: '.drag-handle',
    animation: 300
  };

  edit = false;
  editObject: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventsService,
    private globalServie: GlobalService, globalService: GlobalService,
    private auth: AuthenticationService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<FaqEditModalComponent>,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.adminService.getFaqCategoryContent(this.data.category.category_id).subscribe( (data: any) => {
      this.faq = data.data;
      this.cdr.detectChanges();
    });
  }

  addEntry() {

    this.edit = true;

    this.editObject = {
      'faq_id': 0,
      'category_id' : this.data.category.category_id,
      'question_de' : '',
      'question_en' : '',
      'answer_de' : '',
      'answer_en' : '',
      'sort' : 0
    };

  }

  editEntry(row) {

    this.editObject = row;

    this.edit = true;

  }

  deleteQuestion(id) {
    if (confirm("Are you sure?")) {
      this.adminService.deleteQuestion(id).subscribe((data:any) => {
        this.getData();
      });
    }
  }

  saveQuestion() {

    this.adminService.saveQuestion(this.editObject).subscribe( (data: any) => {
      this.edit = false;
      this.getData();
    });

  }

  saveOrder() {
    this.adminService.saveQuestionOrder(this.faq).subscribe( (data: any) => {
      this.getData();
    });
  }

  cancelEdit() {
    this.editObject = {
      'faq_id': 0,
      'category_id' : this.data.category.category_id,
      'question_de' : '',
      'question_en' : '',
      'answer_de' : '',
      'answer_en' : '',
      'sort' : 0
    };
    this.edit = false;
    this.getData();
  }
}

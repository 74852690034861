import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {PageHeaderModule} from '../../core/page-header/page-header.module';
import {BreadcrumbsModule} from '../../core/breadcrumbs/breadcrumbs.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  MatCardModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatInputModule, MatTableModule,
  MatTabsModule
} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {EditDriverEntryModule} from '../events/event-detail/edit-driver-entry/edit-driver-entry.module';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule} from '@angular/forms';
import {LayoutModule} from '../../core/layout/layout.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [
    CommonModule,
    CommonModule,
    FlexModule,
    CommonModule,
    PageHeaderModule,
    BreadcrumbsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTabsModule,
    EditDriverEntryModule,
    MatGridListModule,
    MatMenuModule,
    MatDividerModule,
    FormsModule,
    MatCardModule,
    MatExpansionModule,
    MatDialogModule,
    MatTableModule,
    LayoutModule,
    RouterModule
  ]
})
export class FooterModule { }

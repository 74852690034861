import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../authentication.service';
import {GlobalService} from '../../../global.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../users.service';
import {clone} from 'lodash-es';

@Component({
  selector: 'elastic-name-change-req',
  templateUrl: './name-change-req.component.html',
  styleUrls: ['./name-change-req.component.scss']
})
export class NameChangeReqComponent implements OnInit {

  orig: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public usersService: UsersService, public globalService: GlobalService, public userService: UsersService, public cdr: ChangeDetectorRef, public auth: AuthenticationService, private dialogRef: MatDialogRef<NameChangeReqComponent>) { }

  ngOnInit() {

    this.orig = clone(this.data);

  }

  changed() {
    if ( (this.data.vorname + " " + this.data.nachname) !== (this.orig.vorname + " " + this.orig.nachname) ) {
      return true;
    }

    return false;
  }

  save() {

    this.usersService.changeName(this.data.vorname, this.data.nachname, this.data.shortname).subscribe( (data: any) => {
      if (data) {
        if (data.success) {
          this.auth.updateUserCookie();
          this.dialogRef.close();
        }
      }
    });

  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalService} from '../../../global.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'elastic-report-self-acceptance',
  templateUrl: './report-self-acceptance.component.html',
  styleUrls: ['./report-self-acceptance.component.scss']
})
export class ReportSelfAcceptanceComponent implements OnInit {

  reports: any;
  report_to_show: any;

  reasons: any;

  answer = 0;
  constructor(
    private globalService: GlobalService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialogRef<any>,
    private confirmDialogService: ConfirmDialogService,

  ) { }

  ngOnInit() {
    this.globalService.selfAcceptWindowOpen = true;
    this.getData();
    this.globalService.getReportingReasons2().subscribe(
      ( response: HttpEvent<any> ) => {
      switch (response.type) {
        case HttpEventType.Response:
          this.reasons = response.body;
          this.cdr.detectChanges();
          break;
      }
    });
  }

  getData() {
    this.globalService.getSelfAcceptanceReports().subscribe( (data: any) => {
      this.reports = data;
      if (data.length == 0) {
        this.globalService.selfAcceptWindowOpen = false;
        this.cdr.detectChanges();
        this.dialog.close();
      }
      this.cdr.detectChanges();
    });

  }

  showReport(report) {
    this.report_to_show = report;
    this.reasons.reasons.forEach( (reason) => {
      if (reason.reason_id == this.report_to_show.reason_id) {
        this.report_to_show.expected_penalty = reason;
      }
    });
  }

  cancel() {
    this.report_to_show = false;
    this.answer = 0;
    this.getData();
  }

  send() {
    const options = {
      title: '',
      message: 'Are you sure with your choice?',
      cancelText: 'No',
      confirmText: 'Yes'
    };
    this.confirmDialogService.open(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.globalService.answerSelfAcceptanceReport(this.report_to_show.report_id, this.answer, this.report_to_show.self_acceptance_answer_text).subscribe( (data: any) => {
          if (data) {
            this.report_to_show = false;
            this.getData();
          }
        });
      }
    });

    /*
    if (confirm("Are you sure?")) {
      //console.log("ANSWER: Report #" + this.report_to_show.report_id + " / Answeroption " + this.answer);




    }*/

  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LIST_FADE_ANIMATION } from '../../utils/list.animation';
import {ChatServiceService} from '../../../pages/chat/chat-service.service';
import {interval } from 'rxjs';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {AuthenticationService} from '../../../authentication.service';
import {UsersService} from '../../../pages/users/users.service';
import {GlobalService} from '../../../global.service';
import {Router} from '@angular/router';
import {BroadCastService} from '../../../broadcast.service';
import {Subscription} from 'rxjs';
import {ConfirmDialogService} from '../../../shared/confirm-dialog/confirm-dialog.service';
import {TeamEventsService} from '../../../pages/team-events/team-events.service';
import {SeasonsService} from '../../../pages/seasons/seasons.service';
import {ToastrService} from 'ngx-toastr';
import {RaceDetailModalComponent} from '../../../pages/events/race-detail-modal/race-detail-modal.component';
import {ConnectorpopupComponent} from './connectorpopup/connectorpopup.component';
import {MatDialog} from '@angular/material';
import {UserDataDialogComponent} from '../../../pages/users/user-data-dialog/user-data-dialog.component';

@Component({
  selector: 'elastic-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION]
})
export class ToolbarNotificationsComponent implements OnInit {

  isOpen: boolean;
  notifications = [];
  demoTriggers = 0;
  signed_up = [];

  messagesLoaded = false;
  notificationsLoaded = false;
  newMessages = {unread: 0};

  newNotifications =  {unread: 0};

  subscription: Subscription;

  accSignedUp = false;

  connectorPopUpSeen = false;

  iconMapping = {
    penalty: 'warning',
    info: 'info',
    license : 'card_membership',
    race_result : 'outlined_flag',
    livery_approved: 'check',
    livery_declined: 'warning',
    season_livery_approved: 'check',
    season_livery_declined: 'warning'
  };

  constructor(
    private cd: ChangeDetectorRef,
    private chatService: ChatServiceService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private globalService: GlobalService,
    private router: Router,
    private broadcastService: BroadCastService,
    private confirmDialogService: ConfirmDialogService,
    private teamEventsService: TeamEventsService,
    private seasonsService: SeasonsService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {
    this.subscription = this.broadcastService.getMessage().subscribe(data => {
      console.log('BroadCast data : ' + JSON.stringify(data));
        this.getMySignedUpRaces();
    });
  }

  ngOnInit() {
    this.newNotifications = {
      unread: 0 * 1
    };

    this.getData();


    interval(120000).subscribe(x => {
      this.getData();
    });

    interval(1000).subscribe(x => {
      this.cd.detectChanges();
    });

  }

  getData() {

    this.auth.updateUserCookie();
    this.chatService.checkNewMessages().subscribe(
      ( response: HttpEvent<any> ) => {
        switch (response.type) {
          case HttpEventType.Response:
            this.messagesLoaded = true;
            this.newMessages = response.body;
            this.cd.detectChanges();
            break;
        }
      });
    this.usersService.checkNotifications().subscribe( (data: any) => {
      this.notifications = data;
      this.notificationsLoaded = true;
      // @ts-ignore
      this.newNotifications.unread = this.notifications.length;
      //this.getMySignedUpRaces();
      this.cd.detectChanges();
    });



    this.getMySignedUpRaces();



  }

  markAsRead(notification) {
    notification.read = true;
  }

  dismiss(notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
    this.triggerDemoNotification();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.triggerDemoNotification();
  }

  onClickOutside() {
    this.isOpen = false;
  }

  triggerDemoNotification() {
    if (this.demoTriggers === 0) {
      this.demoTriggers++;

      setTimeout(() => {
        this.notifications.unshift({
          icon: 'cached',
          name: 'New user registered',
          time: moment().fromNow(),
          read: false,
          colorClass: '',
        });

        this.cd.markForCheck();
      }, 2000);
    } else if (this.demoTriggers === 1) {
      this.demoTriggers++;

      setTimeout(() => {
        this.notifications.unshift({
          icon: 'shopping_basket',
          name: 'User bought your template',
          time: '23 min ago',
          read: false,
          colorClass: 'primary'
        });

        this.cd.markForCheck();
      }, 2000);
    }
  }

  notificationAction(notification) {
    this.usersService.markAsRead(notification.id).subscribe( (data: any) => {
      this.getData();
      if (notification.data.type == 'penalty') {
        this.router.navigateByUrl('users#penalties');
      }
      if (notification.data.type == 'judged') {
        this.router.navigateByUrl('users#outcomes');
      }
      if (notification.data.type == 'license') {
        this.router.navigateByUrl('users');
      }
      if (notification.data.type == 'race_result') {
        this.router.navigateByUrl('events/' + notification.data.event_id + '/race/' + notification.data.race_id);
      }

      if (notification.data.type == 'result_changed') {
        this.router.navigateByUrl('events/' + notification.data.event_id + '/race/' + notification.data.race_id);
      }

      if (notification.data.type == 'livery_approved') {
        this.router.navigateByUrl('endurance/' + notification.data.event_id + '/signup');
      }

      if (notification.data.type == 'livery_declined') {
        this.router.navigateByUrl('endurance/' + notification.data.event_id + '/signup');
      }

      if (notification.data.type == 'season_livery_approved') {
        if (notification.data.team_id != 0) {
          this.router.navigateByUrl('teams/detail/' + notification.data.team_livery);
        } else {

        }
      }

      if (notification.data.type == 'season_livery_declined') {
        if (notification.data.team_id != 0) {
          this.router.navigateByUrl('teams/detail/' + notification.data.team_livery);
        } else {

        }
      }

    });
  }

  markAllAsRead() {
    this.usersService.markAllNotificationsAsRead().subscribe( (data: any) => {
      this.notifications = data;
      this.notificationsLoaded = true;
      // @ts-ignore
      this.newNotifications.unread = this.notifications.length;
      this.cd.detectChanges();
    });
  }

  getMySignedUpRaces() {
    this.usersService.getMySignedUpRaces().subscribe( (data2: any) => {

      if (data2) {
        data2.forEach( (item) => {
          item.serverOpenDate = new Date(item.race_date.replace(' ', 'T') + '+0100');
          item.signOutDate = new Date(item.sign_out_closes.replace(' ', 'T') + '+0100');
          if (item.track.sim_id == 1) {
            this.accSignedUp = true;
          }
        });
        this.signed_up = data2;
        if (this.accSignedUp && this.globalService.serverstatus.status == 0 && !this.connectorPopUpSeen) {
          this.dialog.open(ConnectorpopupComponent, {
            disableClose: false, data: this.auth.currentUser, minWidth: 800, maxWidth: 800
          }).afterClosed().subscribe(result => {
            this.connectorPopUpSeen = true;
          });

        }
      }
    });
  }


  withdraw(event_id, race_id, team_event) {



    if (this.auth.isLoggedIn()) {


      const options = {
        title: 'Are you sure?',
        message: 'Do you really want to withdraw from the race?',
        cancelText: 'No',
        confirmText: 'Yes'
      };
      this.confirmDialogService.open(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          if (team_event == 1) {
            this.teamEventsService.withdrawSeasonRace(event_id, race_id).subscribe( (data) => {
                this.broadcastService.sendMessage('registered');
              }
            );
          } else {
            this.seasonsService.withdraw(race_id).subscribe(
              ( response: HttpEvent<any> ) => {
                switch (response.type) {
                  case HttpEventType.Response:
                    this.broadcastService.sendMessage('registered');
                    break;
                }
              });
          }
        }
      });
    }

  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    /*
    const options = {
      title: 'Lets go race!',
      message: 'Session # copied to clipboard! Paste it now in the ACC server browser to find your server!',
      cancelText: '',
      confirmText: 'OK, thanks'
    };
    this.confirmDialogService.open(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {

      }
    });
    */

    this.toastr.success(val + ' copied to clipboard! Paste it now in the ACC server browser to find your server!');

  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginwindowComponent } from './loginwindow.component';
import {MatButtonModule, MatListModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';



@NgModule({
  declarations: [LoginwindowComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatListModule
  ]
})
export class LoginwindowModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserDataDialogComponent} from './user-data-dialog.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {PipesModule} from '../../../pipes/pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule, MatDividerModule, MatGridListModule, MatInputModule, MatTabsModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [UserDataDialogComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatIconModule,
    PipesModule,
    FormsModule,
    MatInputModule,
    FlexModule,
    MatButtonModule

  ]
})
export class UserDataDialogModule { }

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalService} from '../../global.service';
import {interval} from 'rxjs';

@Component({
  selector: 'elastic-dashboard-socials',
  templateUrl: './dashboard-socials.component.html',
  styleUrls: ['./dashboard-socials.component.scss']
})
export class DashboardSocialsComponent implements OnInit {

  simucubelink: string;

  constructor(private globalService: GlobalService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    this.simucubelink = btoa('https://simucube.com/store/?utm_campaign=simucube-brand&utm_medium=banner&utm_source=lfm&utm_content=logo&utm_term=simucube-text-logo');

    interval(60000).subscribe(x => {
      this.getData();
    });
    this.getData();
  }

  getData() {
    this.checkServersOnline();
  }

  checkServersOnline() {

    this.globalService.checkAccStatus().subscribe( (data: any) => {
      this.globalService.getOnlineDrivers().subscribe( (data2: any) => {
        if (data2) {
          this.globalService.onlinedrivers = data2.online_users;
          this.cdr.detectChanges();
        }
      });
      if (data) {
        this.globalService.serverstatus.text = data.text;
        this.globalService.serverstatus.status = data.server_status;
        //this.globalService.serverstatus.status = 0;
        this.cdr.detectChanges();
      }
    });
  }

}
